<template>
    <div>
        <div class="row">
            <div class="col-md-8">
                <div class="card mb-3">
                    <div class="card-header white">
                        <h6> Banks </h6>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card mb-3">
                    <!-- <button class="btn btn-primary" v-b-modal.createBank>Add</button> -->
                    <button class="btn btn-primary" @click="addBank()">Add</button>
                </div>
            </div>
        </div>
        

        <div class="card" id="profile" role="tabpanel">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                        <tr>
                            <th><strong>S/N</strong></th>
                            <th>Bank Name</th>
                            <th>Bank Account Name</th>
                            <th>Bank Account Number</th>
                            <th><strong>Action</strong></th>
                        </tr> 
                         
                        <tr v-if="loading">
                            <td colspan="5">
                                <b-skeleton-table
                                    :rows="5"
                                    :columns="5"
                                    :table-props="{ bordered: true, striped: true }"
                                ></b-skeleton-table>
                            </td>
                        </tr>
                        <template v-else>
                            <tr v-if="userBanks.length == 0">
                                <td colspan="7">There are no banks</td>
                            </tr>
                                <tr v-else v-for="bank,i in userBanks" :key="i">
                                <td>{{++i}}</td>
                                <td>{{bank.bankName}}</td>
                                <td>{{bank.accountName}}</td>
                                <td>{{bank.accountNumber}}</td>
                                <td>
                                    <div class="dropdown">
                                        <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Edit/Delete
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="position:relative">
                                            <a data-id="2" class="dropdown-item btn" @click="setBank(bank)" v-b-modal.editBank>Edit</a>
                                            <a data-id="2" class="dropdown-item btn" @click="setBank(bank)" v-b-modal.deleteBank>Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>                                                       
                                                                                                                                                                                                                                          
                    </table>
                </div>
            </div>
        </div>

        <modal :modalId="'createBank'" :modalTitle="'Add bank'" :modalSize="'lg'">
            <create-bank @bank-created="createBank"/>
        </modal>

        <modal :modalId="'editBank'" :modalTitle="'Edit bank'" :modalSize="'lg'">
            <b-card v-if="!bank">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <edit-bank v-else :bank="bank" @bank-edited="updateBank"/>
        </modal>

        <modal :modalId="'deleteBank'" :modalTitle="'Delete bank'">
            <b-card v-if="!bank">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <delete-bank v-else :bank="bank" @bank-delete-confirmed="deleteBank"/>
        </modal>
        
    </div>
</template>

<script>
import modal from '@/components/Modal'
import createBank from '@/components/banks/createBank'
import editBank from '@/components/banks/editBank'
import deleteBank from '@/components/banks/deleteBank'
import {notification} from '@/util/notification'
import {mapActions,mapState,mapGetters} from 'vuex'
import vm from '../../main'
import Connect from '@mono.co/connect.js'
import mono from '../../api/mono'
export default {
    components:{
        modal,
        editBank,
        deleteBank,
        createBank
    },

    data(){
        return{
            bank:null,
           // rowKey:Math.random()
        }
    },

    computed:{
        ...mapState({
            submitting:state=>state.submitting,
            loading:state=>state.loading
        }),

        ...mapGetters('bankStore',['userBanks']),
        ...mapGetters('authStore',['authUser']),
    },

    created(){
         if(Object.entries(this.authUser).length == 0){
            this.getUser().then(function(res){
                if(res.status == 200){
                    //alert(res.data._id)
                   vm.$store.dispatch('bankStore/getUserBanks',res.data._id)
                   //this.getUserBanks(res.data._id)
                }
            })
         }else{
            this.getUserBanks(this.authUser._id)
         }
        
    },

    methods:{
        ...mapActions('bankStore',['create','getUserBanks','update','delete']),
        ...mapActions('authStore',['getUser']),

        addBank(){
            var that = this
             const connect = new Connect({ 
             key: process.env.VUE_APP_MONO_PUBLIC_KEY,//'test_pk_2TugdCxELHccsWCaUUHD', //process.env.VUE_MONO_PUBLIC_KEY ,
             onSuccess: function({code}){
                 console.log("auth code", code)
                 mono.create({code:code}).then(res=>{
                     if(res.status == 200){
                         notification.success('Account connected successfully')
                         //this.getUserBanks(this.authUser._id)
                         vm.$store.dispatch('bankStore/getUserBanks',that.authUser._id)
                     }
                 }).catch(err=>{
                     if(err.response.status == 500){
                         notification.error('An error occured')
                     }else{
                         notification.error(err.response.data.message)
                     }
                 })
             },
             onLoad: () => console.log("widget loaded successfully")
        });

        connect.setup();
        connect.open();
        },

        createBank(data){
            //console.log(data.get('bankName'))
            let bank = {
                bankName:data.get('bankName'),
                accountName:data.get('accountName'),
                accountNumber:data.get('accountNumber')
            }
            this.create({userId:this.authUser._id, data:bank})
        },

        setBank(bank){
            this.bank = bank
            console.log('bank set'+bank._id)
        },

        updateBank(formData){
            if(!this.bank){
                notification.error("bank not initialized")
            }
            let bank = {
                bankName:formData.get('bankName'),
                accountName:formData.get('accountName'),
                accountNumber:formData.get('accountNumber')
            }
            this.update({id:this.bank._id,data:bank}).then(res=>{
                if(res.status==200){
                    this.$bvModal.hide('editBank')
                    this.bank = null
                }
            })
            
        },

        deleteBank(bankId){
            this.delete(bankId).then(res=>{
                if(res.status==200){
                    this.$bvModal.hide('deleteBank')
                    this.bank = null
                }
            })
        },

        // setKey(id,rand=null){
        //     if(!rand){
        //         return id
        //     }
        //     return Math.random()
        // }
    }
    
}
</script>