<template>
    <div>
        <div class="card mb-3">
            <div class="card-header white">
                <h6> YOUR ACTIVITIES </h6>
            </div>
        </div>

        <div class="">
            <activity-panel />
        </div>
			<!--Style End 3-->
			
        <!-- <div class="d-flex row row-eq-height my-3">
            
        </div> -->
			
        <div class="d-flex row row-eq-height my-3">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header white">
                        <div class="row justify-content-end">
                            <div class="col">
                                <ul class="nav nav-tabs card-header-tabs nav-material">
                                    <li class="nav-item">
                                        <a class="nav-link text-blue" id="w1-tab1" data-toggle="tab" >Borrowed Loans</a>
                                    </li>	
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-body no-p">
                        <div class="tab-content">
                            <div class="tab-pane show active" id="v-pills-w1-tab1" role="tabpanel" aria-labelledby="v-pills-w1-tab1">
                                <div class="row p-3">
                                    <div class="col-md-12 table-responsive">
                                       <table class="table table-bordered">
                                           <thead>
                                               <tr>
                                                   <th>s/n</th>
                                                   <th>Category</th>
                                                   <th>Status</th>
                                                   <th>Duration</th>
                                                   <th>Date</th>
                                               </tr>
                                           </thead>
                                           <tbody>
                                               <tr v-for="loan,i in borrowedLoans.slice(1,11)" :key="i">
                                                    <td>{{++i}}</td>
                                                    <td>{{loan.loanCategory.type}}</td>
                                                    <td>{{loan.status}}</td>
                                                    <td>{{loan.duration}} {{loan.durationType}}</td>
                                                    <td>{{loan.createdAt}}</td>
                                               </tr>
                                           </tbody>
                                       </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>	
                </div>
            </div>
        </div>
			
        <div class="d-flex row row-eq-height my-3">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header white">
                        <div class="row justify-content-end">
                            <div class="col">
                                <ul class="nav nav-tabs card-header-tabs nav-material">
                                    <li class="nav-item">
                                        <a class="nav-link text-blue" id="w1-tab1" data-toggle="tab" >Recent Payments</a>
                                    </li>	
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-body no-p">
                        <div class="tab-content">
                            <div class="tab-pane animated show active" id="v-pills-w1-tab1" role="tabpanel" aria-labelledby="v-pills-w1-tab1">
                                <div class="row p-3">
                                    <div class="col-md-12">
                                    
                                        <div class="card-body pb-" id="pending">
                                            <ul class="list-group">
                                                <li class=" list-group-item d-flex justify-content-between align-items-center">
                                                    <a href="" class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapseOne">Current Payments</a>
                                                    <template v-if="loading">...</template>
                                                    <a v-else href="#pending" class="btn badge badge-primary badge-pill">{{recentPayments.length}}</a>
                                                </li>												   
                                            </ul>
                                        </div>
                                            <div id="collapse1" class="collapse hidden col-lg-12" aria-labelledby="headingOne" data-parent="#accordionExample" style="">
                                                <div class="tab-content">
                                                    <div class="tab-pane active" id="home" role="tabpanel">
                                                        <div class="">
                                                            <div class="table-responsive" style="overflow:hide !important;">
                                                                <b-card v-if="loading">
                                                                    <b-skeleton animation="throb" width="85%"></b-skeleton>
                                                                    <b-skeleton animation="throb" width="55%"></b-skeleton>
                                                                    <b-skeleton animation="throb" width="70%"></b-skeleton>
                                                                </b-card>
                                                                <div class="table-responsive">
                                                                    <table class="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>s/n</th>
                                                                                <th>Category</th>
                                                                                <th>Description</th>
                                                                                <th>Amount</th>
                                                                                <th>Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-if="recentPayments.length == 0">
                                                                                <td colspan="5" class="text-center"> There are no recent payments</td>
                                                                            </tr>
                                                                            <tr v-else v-for="pay,i in recentPayments" :key="i">
                                                                            <td>{{++i}}</td>
                                                                            <td>{{pay.loanUser.loanCategory.type}}</td>
                                                                            <td>{{pay.loanUser.description}}</td>
                                                                            <td>{{pay.amount}}</td>
                                                                            <td>{{pay.date}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                    </div>
                </div>
            </div>
        </div>     
    </div>
</template>

<script>
import {mapState,mapGetters,mapActions} from 'vuex'
import activityPanel from '@/components/admin/activityPanel'
//import frontPageMessage from '@/components/admin/frontPageMessage'
//import pendingOrders from '@/components/admin/pendingOrders'
export default {
    components:{
        activityPanel,
        //frontPageMessage,
        //pendingOrders
    },
    data(){
        return{
            key:''
        }
    },

    computed:{
        ...mapState({
            loading:state=>state.loading
        }),
        ...mapGetters('loanStore',['borrowedLoans','recentPayments']),
        ...mapGetters('authStore',['authUser']),
        //...mapGetters('userStore',['totalUsers']),
    },

    created(){
      
        if(this.borrowedLoans.length == 0 || this.recentPayments.length == 0){
            this.getBorrowedLoans()
            this.getRecentPayments()
        }

        // if(! this.totalUsers){
        //     this.getTotalUsers()
        // }
         
    },

    methods:{
        ...mapActions('authStore',['getUser']),
        ...mapActions('loanStore',['getBorrowedLoans','getRecentPayments']),
        //...mapActions('userStore',['getTotalUsers'])
    }
}
</script>