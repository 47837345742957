import api from '@/api/next-of-kin'
import {notification} from '@/util/notification'

export default{

    async get({commit},userId){
        try {
            commit('loading',null,{root:true})
            const res = await api.userNextOfKin(userId)
            if(res.status==200){
                commit('nextOfKin',res.data)
                
            }else{
                notification.error(res.message)
            }
            commit('loaded',null,{root:true})
        } catch (error) {
            LogError(commit,error,'loaded')
        }
    },

    async create({commit},data){
        try {
            commit('submitting',null,{root:true})
            const res = await api.create(data.userId,data.data)
            processResponse(commit,res,'nextOfKin','next of kin created successfully')
            commit('submitted',null,{root:true})
            return res
        } catch (error) {
            LogError(commit,error,'submitted')
        }
    },

    async update({commit},data){
        try {
            commit('submitting',null,{root:true})
            const res = await api.update(data.id,data.data)
            processResponse(commit,res,'nextOfKin','user updated successfully')
            commit('submitted',null,{root:true})
            return res
        } catch (error) {
            LogError(commit,error,'submitted')
        }
    },

    async delete({commit},id){
        try {
            commit('submitting',null,{root:true})
            const res = await api.delete(id)
            processResponse(commit,res,'submitted',"User deleted successfully")
        } catch (error) {
            LogError(commit,error,'submitted')
        }
    }

}

const LogError = (commit,err,commitType)=>{
    if(err.response !== undefined){
        let {response} = err
        //console.log(err.response)
        response.status==422 ? notification.error(response.data.message) : (response.data.message != undefined) 
        ? notification.error(response.data.message) : notification.error('An error occred')
    }else{
        console.log(err)
        notification.error("Response not found")
    }
    commit(commitType,null,{root:true})
}

const processResponse = (commit,res,commitType,successMsg=null)=>{
    if(res.status !== undefined){
        let {status,message,data} = res
        if(status==200){
           if(commit) commit(commitType,data)
           if(successMsg) notification.success(successMsg)
        }else{
            notification.error(message)
        }
    }else{
        notification.error("response not found")
    }
}