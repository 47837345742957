<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="card" id="incentive">
                    <div class="card-header">
                        
                    </div>
                    <div class="card-body">
                        <form class="form-horizontal form-materia" id="create-loan-category-form" enctype="multipart/form-data" @submit.prevent="submit()">
                            
                            <div class="form-group">
                                <label class="col-md-12">Type</label>
                                <div class="col-md-12">
                                <input type="text" name="type" class="form-control form-control-line">
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <label class="col-md-12">Duration</label>
                                <div class="col-md-12">
                                <input type="text" name="duration" class="form-control form-control-line">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12">Duratio Type</label>
                                <div class="col-md-12">
                                <input type="text" name="durationType" class="form-control form-control-line">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12">Minimum amount</label>
                                <div class="col-md-12">
                                    <input type="text" name="minAmount" class="form-control form-control-line">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12">Maximum amount</label>
                                <div class="col-md-12">
                                    <input type="text" name="maxAmount" class="form-control form-control-line">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12">Interest Rate</label>
                                <div class="col-md-12">
                                    <input type="text" name="interestRate" class="form-control form-control-line">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-12">
                                    <button class="btn btn-primary" id="add-incentives">Create Loan Category</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        submit(){
            let form = document.getElementById('create-loan-category-form')
            let data = new FormData(form)
            this.$emit('loan-category-created',data)
        }
    }
}
</script>