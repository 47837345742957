import dashboard from '@/views/admin/dashboard'
import adminLayout from '@/components/AdminLayout'
import loanCategory from '@/views/admin/loanCategory'
import users from '@/views/admin/users'
import loanHistory from '@/views/admin/loanHistory'
import security from '@/views/admin/security'
import canclationRequests from '@/views/admin/loanCanclationRequests'
import approvalRequests from '@/views/admin/loanApprovalRequests'
import setting from '@/views/admin/settings'

export default [
    {
        path:'/admin',
        component:adminLayout,
        meta:{
          isAdmin:true,
          requiresAuth:true
        },
        children:[
          {
            path:'dashboard',
            name:'admin-dashboard',
            component:dashboard,
            meta:{
                isAdmin:true,
                requiresAuth:true
            }
          },
          {
            path:'loan-category',
            name:'admin-loan-category',
            component:loanCategory,
            meta:{
                isAdmin:true,
                requiresAuth:true
            }
          },
          {
            path:'users',
            name:'admin-users',
            component:users,
            meta:{
                isAdmin:true,
                requiresAuth:true
            }
          },
          {
            path:'loan-history',
            name:'admin-loan-history',
            component:loanHistory,
            meta:{
                isAdmin:true,
                requiresAuth:true
            }
          },
          {
            path:'loan-canclation-requests',
            name:'admin-loan-canclation-requests',
            component:canclationRequests,
            meta:{
                isAdmin:true,
                requiresAuth:true
            }
          },
          {
            path:'loan-approval-requests',
            name:'admin-loan-approval-requests',
            component:approvalRequests,
            meta:{
                isAdmin:true,
                requiresAuth:true
            }
          },
          {
            path:'security',
            name:'admin-security',
            component:security,
            meta:{
                isAdmin:true,
                requiresAuth:true
            }
          },
          {
            path:'setting',
            name:'admin-setting',
            component:setting,
            meta:{
                isAdmin:true,
                requiresAuth:true
            }
          }
        ]
    }
]