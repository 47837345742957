<template>
    <div>
        <header class="blue accent-3 relative">
            <div class="container-fluid text-blue">
                <div class="row justify-content-between">
                    <ul class="nav nav-material nav-material-white responsive-tab" id="v-pills-tab" role="tablist">
                        <!-- <li>
                            <a class="nav-link active" id="v-pills-wallet-summary-tab" data-toggle="pill"  href="#v-pills-wallet-summary" role="tab" aria-controls="v-pills-wallet-sumary"><i class="icon icon-lock4" ></i>Manage Google 2Factor Authenticator</a>
                        </li> -->
                        <li>
                            <a class="nav-link" id="v-pills-Wallet-Detailed-Calculation-tab" data-toggle="pill" href="#v-pills-Wallet-Detailed-Calculation" role="tab" aria-controls="v-pills-Wallet-Detailed-Calculation" aria-selected="false"><i class="icon  icon-key6 "></i>Update Settings</a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
        <br/>
        <div class="animated ">
            <div class="tab-content" id="v-pills-tabContent">
                
                <div class="tab-pane fade show active" id="v-pills-Wallet-Detailed-Calculation" role="tabpanel" aria-labelledby="v-pills-Wallet-Detailed-Calculation-tab">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-header bg-white">
                                    <h6 class="blue-text"><strong class="font-weight-bold">Setting table</strong></h6>
                                </div>
                                <div class="card-body">
                                    <table class="table table-hover">
                                        <thead>
                                            <th>S/N</th>
                                            <th>Card Auth.Amount</th>
                                            <th>Loan Processsor</th>
                                        </thead>
                                        <tbody>
                                            <template v-if="loading">
                                                <tr><td colspan="3">Loading..</td></tr>
                                            </template>
                                            <tr v-else>
                                                <td>1</td>
                                                <td>{{settings.cardCreationCharge}}</td>
                                                <td>{{settings.loanProcessor}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            
                            <div class="card">
                                <div class="card-header bg-white">
                                    <h6 class="blue-text"><strong class="font-weight-bold">Update COnfigurations</strong></h6>
                                </div>
                                <div class="col-lg-6 mx-md-auto">
                                    <br>
                                    <form @submit.prevent="submit()">
                                        <div class="form-group"><label>Paystack Charge Amount</label>
                                            <input required v-model="form.cardCreationCharge" type="text" class="form-control form-control-lg" placeholder="Paystack charge amount">
                                        </div>
                                        <div class="form-group"><label>Loan Processsor</label>
                                            <select v-model="form.loanProcessor" class="form-control form-control-lg" required>
                                                <option v-for="item,i in loanProcessors " :value="item" :key="i">{{item}}</option>
                                            </select>
                                        </div>
                                        <input type="submit" class="btn btn-primary btn-lg btn-block" value="Send">
                                    </form>
                                </div>
                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions,mapState,mapGetters} from 'vuex'
export default {

    data(){
        return{
            form:{
                cardCreationCharge:0,
                loanProcessor:'Select processor'
            }
        }
    },

    created(){
        if(Object.entries(this.settings).length == 0){
            this.getSettings().then((res)=>{
                if(res.status ==200){
                    this.form.cardCreationCharge = res.data.cardCreationCharge
                    this.form.loanProcessor = res.data.loanProcessor
                }
            })
        }
    },

    computed:{
        ...mapState({
            submitting:state=>state.submitting,
            loading:state=>state.loading
        }),

        loanProcessors(){
            return ['Select processor','mono','paystack'];
        },

        ...mapGetters('settingStore',['settings'])
    },
    
    methods:{
        ...mapActions('settingStore',['getSettings','create','update']),

        submit(){
            //alert(this.settings._id)
            if(this.settingExists()){
                this.form.id = this.settings._id
                this.update(this.form)
            }else{
                this.create(this.form)
            }
            
        },

        settingExists(){
           return this.settings._id ? true : false
        }
    }
}
</script>