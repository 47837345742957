import endPoints from "./endpoints"
import {http} from './axios-config'

export default {

    all(){
        return http().get(endPoints.settings)
    },

    create(data){
        return http().post(`${endPoints.settings}`,data)
    },

    update(id,data){
        return http().put(`${endPoints.settings}/${id}`,data)
    },

}