

export default {
    
    cards(state,data){
        state.cards = data
    },

    userCards(state,data){
        state.userCards = data
    },

    storeBank(state,data){
        state.cards.push(data)
    },

    // updateUserBank(state,data){
    //     let prods = state.userBanks
    //     let index = prods.findIndex(ele=>ele._id==data._id)
    //     if(index == -1){
    //         throw new Error("Bank not found on client")
    //     }
    //     prods.splice(index,1,data)
    // },
    
    // updateBank(state,data){
    //     let prods = state.banks
    //     let index = prods.findIndex(ele=>ele._id==data._id)
    //     if(index == -1){
    //         throw new Error("Bank not found on client")
    //     }
    //     prods.splice(index,1,data)
    // },

    deleteCard(state,data){
        let prods = state.banks
        let index = prods.findIndex(ele=>ele.id==data.id)
        prods.splice(index,1)
    }
}