import Vue from "vue";
import Vuex from "vuex";
import authStore from "./auth-store";
import loanCategoryStore from "./loan-category-store";
import userStore from './user-store'
import nextOfKinStore from './next-of-kin-store'
import bankStore from './banks-store'
import loanStore from './loan-store'
import cardStore from './card-store'
import settingStore from './setting-store'

Vue.use(Vuex);

export default new Vuex.Store({
    state(){
        return{
            submitting:false,
            loading:false,
            activeMenu:null
        }
    },

    mutations:{
        loading(state){
            state.loading =true
        },
        loaded(state){
            state.loading = false
        },
        submitting(state){
            state.submitting = true
        },
        submitted(state){
            state.submitting = false
        }
    },

    modules:{
        userStore,
        authStore,
        loanCategoryStore,
        nextOfKinStore,
        bankStore,
        loanStore,
        cardStore,
        settingStore
    }
})