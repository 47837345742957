import endPoints from "./endpoints"
import {http} from './axios-config'

export default {

    create(data){
        return http().post(`${endPoints.monoData}`,data)
    },


}