export default {
    
    loan(state,data){
        state.loan = data
    },

    borrowedLoans(state,data){
         state.borrowedLoans = data
    },

    recentPayments(state,data){
         state.recentPayments = data
    },

    loanHistory(state,data){
        return state.loanHistory = data
    },

    loanPayments(state,data){
        return state.loanPayments = data
    },

    totalLoans(state,data){
        return state.totalLoans = data
    },

    totalOwings(state,data){
        return state.totalOwings = data
    },

    canclationRequests(state,data){
        return state.canclationRequests = data
    },

    approvalRequests(state,data){
        return state.approvalRequests = data
    },
}