<template>
    <div class="row">
        <div class="col-md-6">
            <div class="card-header bg-white">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">Full name</strong> <span class="float-right s-12">{{fullname ? fullname : 'N/A'}}</span></li>
                    <li class="list-group-item"><i class="icon icon-phone2 text-info"></i><strong class="s-12 blue-text">Phone</strong> <span class="float-right s-12">{{authUser.phone ? authUser.phone : 'N/A'}}</span></li>
                    <li class="list-group-item"><i class="icon icon-mail-envelope-open6 text-info"></i><strong class="s-12 blue-text">Email</strong> <span class="float-right s-12">{{authUser.email}}</span></li>
                    <li class="list-group-item"><i class="icon icon-user-circle-o text-info"></i> <strong class="s-12 blue-text">Gender</strong> <span class="float-right s-12">{{authUser.gender}}</span></li>
                    <li class="list-group-item"><i class="icon icon-level-up  text-info"></i><strong class="s-12 blue-text">state</strong> <span class="float-right s-12">{{authUser.state}}</span></li>
                    <li class="list-group-item"><i class="icon icon-level-up  text-info"></i><strong class="s-12 blue-text">Address</strong> <span class="float-right s-12">{{authUser.address}}</span></li>
                </ul>
            </div>
            <div class=" my-3" >
                <div class="card" style="background-color:white;">
                    <div class="card-body text-center  p-5 ">
                        <div class="avatar avatar-xl mb-3 ">
                            <img class="user_avatar" :src="photo" alt="">
                        </div>
                        <div>
                            <form id="photo" @submit.prevent="submitPhoto()">
                                <div class="form-group m-0">
                                    <label for="name" class="col-form-label s-12 blue-text">Profile Picture</label>
                                    <input name="photo" placeholder="upload picture" class="form-control r-0 light s-12 " type="file">
                                </div>
                                <br/>
                                <button type="submit" class="btn btn-info">
                                    <i class="icon-save mr-2"></i>Update Photo
                                </button>
                            </form>
                        </div>
                    </div>
                </div>	
            </div>
            <br>

        </div>
        <div class="col-md-6">
            <div class="row">
                <!-- bar charts group -->
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header bg-white">
                            <h6 class="blue-text"><strong class="font-weight-bold">Profile Data</strong></h6>
                        </div>
                        <div class="card-body">
                            <form action="" id="profile" method="POST" @submit.prevent="submit()">
                                    
                                    <div class="card no-b  no-r">
                                        <div class="card-body">
                                            <div class="form-row">
                                                <div class="col-md-12">
                                                    <div class="form-group m-0">
                                                        <label for="name" class="col-form-label s-12 blue-text"></label>
                                                        <input name="firstName" :value="authUser.firstName" placeholder="Enter full Name" class="form-control r-0 light s-12 " type="text">
                                                    </div>

                                                    <div class="form-group m-0">
                                                        <label for="name" class="col-form-label s-12 blue-text">Last Name</label>
                                                        <input name="lastName" :value="authUser.lastName" placeholder="Enter full Name" class="form-control r-0 light s-12 " type="text">
                                                    </div>
            
                                                    <div class="form-row">
                                                        <div class="form-group col-6 m-0">
                                                            <label for="cnic" class="col-form-label s-12 blue-text"><i class="icon-phon"></i>Phone Number</label>
                                                            <input name="phone" :value="authUser.phone" placeholder="Enter Form B or CNIC Number" class="form-control r-0 light s-12 date-picker" type="text">
                                                        </div>
                                                        <div class="form-group col-6 m-0">
                                                            <label for="dob" class="col-form-label s-12 blue-text"><i class="icon-emai mr-2"></i>Email</label>
                                                            <input name="email" readonly :value="authUser.email" placeholder="email address" class="form-control r-0 light s-12 datePicker"  type="email">
                                                        </div>
                                                    </div>

                                                    <div class="form-row ">
                                                        <div class="form-group col-md-4 m-0">
                                                            <label for="dob" class="col-form-label s-12 blue-text">Select Gender</label>
                                                            <br>
                                                            <div class="">
                                                                <select name="gender" class=" form-control" placeholder="Gender">
                                                                    <template v-if="userExists()">
                                                                        <option :value="authUser.gender">{{authUser.gender}}</option>
                                                                    </template>
                                                                    <template v-else>
                                                                        <option>select</option>
                                                                    </template>
                                                                    <option value="male">Male</option>
                                                                    <option value="female">Female</option>
                                                                </select>   
                                                            </div>   
                                                        </div>

                                                        <div class="form-group col-md-4 m-0">
                                                            <label for="dob" class="col-form-label s-12 blue-text">State</label>
                                                            <br>
                                                            <div class="">
                                                                <select name="state" class="select2 form-control"  placeholder="Gender" >
                                                                    <template v-if="userExists()">
                                                                        <option :value="authUser.state" selected>{{authUser.state}}</option>
                                                                    </template>
                                                                    <template v-else>
                                                                        <option>select</option>
                                                                    </template>
                                                                    <option value="portharcort">Portharcort</option>
                                                                    <option value="lagos">Lagos</option> 
                                                                </select>   
                                                            </div>   
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="form-group m-0">
                                                        <label for="address"  class="col-form-label s-12 blue-text">Address</label>
                                                        <textarea name="address" :value="authUser.address" rows="5" type="text" class="form-control r-0 light s-12" id="address"
                                                        placeholder="Enter Address"></textarea>
                                                    </div>	
                                                </div>

                                            </div>
                                        </div>	
                                        
                                        <div class="card-body">
                                            <input type="hidden" name="id" value="960">
                                            <button type="submit" class="btn btn-info btn-lg"><i class="icon-save mr-2"></i>Update Data</button>
                                        </div>
                                    </div>
                                </form>
                        </div>
                    </div>
                </div>
                <!-- /bar charts group -->
                
            </div>
        </div>
    </div>
</template>
<script>

import {notification} from '@/util/notification'
import {mapActions,mapState,mapGetters} from 'vuex'
//import api from '../../api/next-of-kin'
//mport action from '../../store/actions/next-of-kin-actions'
//import vm from '../../main'
export default {
    components:{
        
    },

    data(){
        return{
            nok:null,
           // rowKey:Math.random()
        }
    },

    computed:{
        ...mapState({
            submitting:state=>state.submitting,
            loading:state=>state.loading
        }),

        ...mapGetters('userStore',['profilePhoto']),
        ...mapGetters('authStore',['authUser']),

        photo(){
            return this.profilePhoto ?
             process.env.VUE_APP_UPLOAD_URL+'/'+this.profilePhoto
             : '/assets/img/dummy/u1.png'
        },

        fullname(){
            return this.authUser.firstName+" "+this.authUser.lastName
        }
    },

    created(){
        this.setData()
        
    },

    methods:{
        ...mapActions('userStore',['create','get','update','delete',
        'updateProfilePhoto','getProfilePhoto']),
        ...mapActions('authStore',['getUser']),

        createNextOfKin(){
            if(!this.authUser){
                notification.error("auth user not initialized")
                return
            }
            let form = document.getElementById('profile')
            let data = new FormData(form)
            let user = {
                firstName:data.get('firstName'),
                lastName:data.get('lastName'),
                email:data.get('email'),
                phone:data.get('phone'),
                state: data.get('state'),
                address: data.get('address'),
                gender: data.get('gender')
            }
            this.create({userId:this.authUser._id,data:user})
        },

        updateNextOfKin(){
            if(!this.authUser){
                notification.error("user not initialized")
                return
            }
            let form = document.getElementById('profile')
            let data = new FormData(form)

            let user = {
                //id:this.nextOfKin._id,
                firstName:data.get('firstName'),
                lastName:data.get('lastName'),
                email:data.get('email'),
                phone:data.get('phone'),
                state: data.get('state'),
                address: data.get('address'),
                gender: data.get('gender')
            }
            this.update({id:this.authUser._id, data:user})
            
        },

        submit(){
            this.updateNextOfKin()
        },

        userExists(){
            //console.log(this.nextOfKin._id)
            //alert('this.nextOfkin.firstName')
          return ( this.authUser._id) ? true : false
          //alert(isTrue)
        },

        setData(){
            var that = this
            if(Object.entries(this.authUser).length == 0){
                this.getUser().then(res=>{
                    if(res.status == 200){
                        that.getProfilePhoto(that.authUser._id)
                    } 
                })
            }else{
                that.getProfilePhoto(that.authUser._id)
                this.get(this.authUser._id)
            }
        },

        submitPhoto(){
            let form = document.getElementById('photo')
            let data = new FormData(form)
            // let picture = {
            //     photo: data.get('photo')
            // }
            this.updateProfilePhoto({id:this.authUser._id,data:data})
        }
    }
    
}
</script>