import endPoints from "./endpoints"
import {http} from './axios-config'

export default {

    all(){
        return http().get(endPoints.cards)
    },

    userCards(userId){
        return http().get(`${endPoints.cards}/${userId}`)
    },

    create(userId,data){
        return http().post(`${endPoints.cards}/${userId}`,data)
    },

    // update(id,data){
    //     return http().put(`${endPoints.banks}/${id}`,data)
    // },

    show(id){
        return http().get(`${endPoints.cards}/${id}`)
    },

    // delete(id){
    //     return http().delete(`${endPoints.banks}/${id}`)
    // },

}