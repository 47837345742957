import actions from '../store/actions/next-of-kin-actions'
import getters from '../store/getters/next-of-kin-getters'
import mutations from '../store/mutations/next-of-kin-mutations'

export default{
    namespaced:true,
    state(){
        return{
            nextOfKin:{},
            lil:12
        }
    },
    actions:{
        ...actions
    },
    getters:{
        ...getters
    },
    mutations:{
        ...mutations
    }
}