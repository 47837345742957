import endPoints from "./endpoints"
import {http} from './axios-config'

export default {

    verify(reference){
        console.log(reference)
        return http().post(`${endPoints.payments}/verify/${reference}`)
    },

    authorizationCharge(){
        return http().get(`${endPoints.payments}/charge-authorization-amount`)
    },

    

}