import { apiVersion } from "./axios-config";

export default  {
    auth: `${apiVersion}/auth`,
    //adminAuth: `${apiVersion}/admin-auth`,
    users: `${apiVersion}/users`,
    banks: `${apiVersion}/banks`,
    loanCategories: `${apiVersion}/loan-categories`,
    nextOfKins: `${apiVersion}/next-of-kins`,
    monoData: `${apiVersion}/mono`,
    borrowLoan: `${apiVersion}/borrow-loans`,
    loans: `${apiVersion}/loans`,
    profile: `${apiVersion}/user-profile`,
    payments: `${apiVersion}/payments`,
    cards: `${apiVersion}/cards`,
    settings: `${apiVersion}/settings`
};