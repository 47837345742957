<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="card-header">Guarantor</div>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-md-6">
                <div class="card-header bg-white">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">Full name</strong> <span class="float-right s-12">{{fullname ? fullname : 'N/A'}}</span></li>
                        <li class="list-group-item"><i class="icon icon-phone2 text-info"></i><strong class="s-12 blue-text">Phone</strong> <span class="float-right s-12">{{getNok ? getNok.phone : 'N/A'}}</span></li>
                        <li class="list-group-item"><i class="icon icon-mail-envelope-open6 text-info"></i><strong class="s-12 blue-text">Email</strong> <span class="float-right s-12">{{getNok ? getNok.email : 'N/A'}}</span></li>
                        <li class="list-group-item"><i class="icon icon-user-circle-o text-info"></i> <strong class="s-12 blue-text">Gender</strong> <span class="float-right s-12">{{getNok ? getNok.gender : 'N/A'}}</span></li>
                        <li class="list-group-item"><i class="icon icon-level-up  text-info"></i><strong class="s-12 blue-text">Relationship</strong> <span class="float-right s-12">{{getNok? getNok.relationship: 'N/A'}}</span></li>
                        <li class="list-group-item"><i class="icon icon-level-up  text-info"></i><strong class="s-12 blue-text">state</strong> <span class="float-right s-12">{{getNok? getNok.state : 'N/A'}}</span></li>
                        <li class="list-group-item"><i class="icon icon-level-up  text-info"></i><strong class="s-12 blue-text">Address</strong> <span class="float-right s-12">{{getNok? getNok.address:'N/A'}}</span></li>
                    </ul>
                </div>
                <!-- <div class=" my-3" >
                    <div class="card" style="background-color:white;">
                        <div class="card-body text-center  p-5 ">
                            <div class="avatar avatar-xl mb-3 ">
                                <img class="user_avatar" src="assets/img/dummy/u1.png" alt="">
                            </div>
                            
                        </div>
                    </div>	
                </div> -->
                <br>

            </div>
            <div class="col-md-6">
                <div class="row">
                    <!-- bar charts group -->
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header bg-white">
                                <h6 class="blue-text"><strong class="font-weight-bold">Profile Data</strong></h6>
                            </div>
                            <template v-if="!loading">
                                <div class="card-body">
                                    <form action="" id="next-of-kin" method="POST" @submit.prevent="submit()">
                                        
                                        <div class="form-row">
                                            <div class="col-md-12">
                                                <div class="form-group m-0">
                                                    <label for="name" class="col-form-label s-12 blue-text"></label>
                                                    <input name="firstName" :value="getNok.firstName" placeholder="Enter full Name" class="form-control r-0 light s-12 " type="text">
                                                </div>

                                                <div class="form-group m-0">
                                                    <label for="name" class="col-form-label s-12 blue-text">Last Name</label>
                                                    <input name="lastName" :value="getNok.lastName" placeholder="Enter full Name" class="form-control r-0 light s-12 " type="text">
                                                </div>
        
                                                <div class="form-row">
                                                    <div class="form-group col-6 m-0">
                                                        <label for="cnic" class="col-form-label s-12 blue-text"><i class="icon-phon"></i>Phone Number</label>
                                                        <input name="phone" :value="getNok.phone" placeholder="Enter Phone Number" class="form-control r-0 light s-12 date-picker" type="text">
                                                    </div>
                                                    <div class="form-group col-6 m-0">
                                                        <label for="dob" class="col-form-label s-12 blue-text"><i class="icon-emai mr-2"></i>Email</label>
                                                        <input name="email" :value="getNok.email" placeholder="email address" class="form-control r-0 light s-12 datePicker"  type="email">
                                                    </div>
                                                </div>

                                                <div class="form-row ">
                                                    <div class="form-group col-md-4 m-0">
                                                        <label for="dob" class="col-form-label s-12 blue-text">Select Gender</label>
                                                        <br>
                                                        <div class="">
                                                            <select name="gender" class=" form-control" placeholder="Gender">
                                                                <template v-if="nokExists()">
                                                                    <option :value="getNok.gender">{{getNok.gender}}</option>
                                                                </template>
                                                                <template v-else>
                                                                    <option>select</option>
                                                                </template>
                                                                <option value="male">Male</option>
                                                                <option value="female">Female</option>
                                                            </select>   
                                                        </div>   
                                                    </div>

                                                    <div class="form-group col-md-4 m-0">
                                                        <label for="dob" class="col-form-label s-12 blue-text">Relationship</label>
                                                        <br>
                                                        <div class="">
                                                            <select name="relationship" class="select2 form-control"  placeholder="Gender" >
                                                                <template v-if="nokExists()">
                                                                    <option :value="getNok.relationship" selected>{{getNok.relationship}}</option>
                                                                </template>
                                                                <template v-else>
                                                                    <option>select</option>
                                                                </template>
                                                                <option value="parent">Parent</option>
                                                                <option value="sibling">Sibling</option>
                                                                <option value="cousine">Cousine</option>
                                                                <option value="offspring">Offspring</option>
                                                                <option value="spouse">Spouse</option>
                                                            </select>   
                                                        </div>   
                                                    </div>

                                                    <div class="form-group col-md-4 m-0">
                                                        <label for="dob" class="col-form-label s-12 blue-text">State</label>
                                                        <br>
                                                        <div class="">
                                                            <select name="state" class="select2 form-control"  placeholder="Gender" >
                                                                <template v-if="nokExists()">
                                                                    <option :value="getNok.state" selected>{{getNok.state}}</option>
                                                                </template>
                                                                <template v-else>
                                                                    <option>select</option>
                                                                </template>
                                                                <option value="lagos">Lagos</option>
                                                                <option value="lagos">Oyo</option>
                                                                
                                                            </select>   
                                                        </div>   
                                                    </div>
                                                </div>
                                                
                                                <div class="form-group m-0">
                                                    <label for="address"  class="col-form-label s-12 blue-text">Address</label>
                                                    <textarea name="address" :value="getNok.address" rows="5" type="text" class="form-control r-0 light s-12" id="address"
                                                    placeholder="Enter Address"></textarea>
                                                </div>	
                                            </div>

                                        </div>
                        
                                        <div class="mt-2">
                                            <button type="submit" class="btn btn-info btn-lg"><i class="icon-save mr-2"></i>Update Data</button>
                                        </div>
                                    </form>
                                </div>
                            </template>
                        </div>
                    </div>
                    <!-- /bar charts group -->
                    
                </div>
            </div>
        </div>
    </div>
     
</template>

<script>

import {notification} from '@/util/notification'
import {mapActions,mapState,mapGetters} from 'vuex'
//import api from '../../api/next-of-kin'
//mport action from '../../store/actions/next-of-kin-actions'
import vm from '../../main'
export default {
    components:{
        
    },

    data(){
        return{
            nok:null,
           // rowKey:Math.random()
        }
    },

    computed:{
        ...mapState({
            submitting:state=>state.submitting,
            loading:state=>state.loading
        }),

        ...mapGetters('nextOfKinStore',['nextOfKin']),
        ...mapGetters('authStore',['authUser']),

        getNok(){
            return this.nextOfKin
        },

        fullname(){
            if(!this.nextOfKin) return null
            return this.nextOfKin.firstName+" "+this.nextOfKin.lastName
        }
    },

    created(){
        this.setData()
        
    },

    methods:{
        ...mapActions('nextOfKinStore',['create','get','update','delete']),
        ...mapActions('authStore',['getUser']),

        createNextOfKin(){
            if(!this.authUser){
                notification.error("auth user not initialized")
                return
            }
            let form = document.getElementById('next-of-kin')
            let data = new FormData(form)
            let nextOfKin = {
                firstName:data.get('firstName'),
                lastName:data.get('lastName'),
                email:data.get('email'),
                phone:data.get('phone'),
                state: data.get('state'),
                address: data.get('address'),
                relationship: data.get('relationship'),
                gender: data.get('gender')
            }
            this.create({userId:this.authUser._id,data:nextOfKin})
        },

        updateNextOfKin(){
            if(!this.nextOfKin){
                notification.error("nextOfKin not initialized")
                return
            }
            let form = document.getElementById('next-of-kin')
            let data = new FormData(form)

            let nextOfKin = {
                //id:this.nextOfKin._id,
                firstName:data.get('firstName'),
                lastName:data.get('lastName'),
                email:data.get('email'),
                phone:data.get('phone'),
                relationship: data.get('relationship'),
                state: data.get('state'),
                address: data.get('address'),
                gender: data.get('gender')
            }
            this.update({id:this.nextOfKin._id, data:nextOfKin})
            
        },

        deleteNextOfKin(nextOfKinId){
            this.delete(nextOfKinId)
        },

        submit(){
            if(Object.entries(this.nextOfKin).length == 0){
                this.createNextOfKin()
            }else{
                this.updateNextOfKin()
            }
        },

        nokExists(){
            //console.log(this.nextOfKin._id)
            //alert('this.nextOfkin.firstName')
          return ( this.nextOfKin._id) ? true : false
          //alert(isTrue)
        },

        setData(){
            if(Object.entries(this.authUser).length == 0){
                this.getUser().then(function(res){
                    if(res.status == 200){
                        //alert(res.data._id)
                    vm.$store.dispatch('nextOfKinStore/get',res.data._id)
                    }
                })
            }else{
                this.get(this.authUser._id)
            }
        }
    }
    
}
</script>