
export default {

    cards(state){
        return state.cards
    },

    userCards(state){
        return state.userCards
    },

    single(state){
        return (id)=>{
            return state.cards.find(ele=>ele.id==id)
        }
    }
}