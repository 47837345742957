<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="card" id="incentive">
                    <div class="card-header">
                        Delete user
                    </div>
                    <div class="card-body">
                        <div class="alert alert-info">
                            Are sure to delete this user - {{user.firstName}}
                        </div>
                        <button class="btn btn-danger" @click="deleteUser()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        user:{
            type:Object,
            required:true
        }
    },

    methods:{
        deleteUser(){
            this.$emit('user-delete-confirmed',this.user._id)
        }
    }
}
</script>