<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-3">
                    <div class="card-header white">
                        <h6> Loan Categories </h6>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="loading">
            <b-card>
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
        </template>
        <template v-else>
            <div class="row">
                <template v-if="loanCategories.length == 0">
                    <div class="col-md-12 text-center alert alert-info">
                        There are no loan  categories
                    </div>
                </template>
                <div v-else v-for="loanCategory,i in loanCategories" :key="i" class="col-md-4">
                    <div class="card">
                        <img class="card-img-top" src="/assets/img/demo/portfolio/p4.jpg" alt="Card image cap">
                        <div class="card-body">
                            <h5 class="card-title">{{loanCategory.type}}</h5>
                            <p class="card-text">
                                {{loanCategory.description}}
                            </p>
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Min amount
                                <span class="float-right badge badge-primary r-4">{{loanCategory.minAmount.toLocaleString('en-US')}}</span>
                            </li>
                            <li class="list-group-item">Max Amount
                                <span class="float-right badge badge-primary r-4">{{loanCategory.maxAmount.toLocaleString('en-US')}}</span>
                            </li>
                            <li class="list-group-item">Duration
                                <span class="float-right badge badge-primary r-4">{{loanCategory.duration}} {{loanCategory.durationType}}</span>
                            </li>
                            <li class="list-group-item">Interest Rate
                                <span class="float-right badge badge-primary r-4">{{loanCategory.interestRate}} %</span>
                            </li>
                        </ul>
                        <div class="card-footer">
                            <span class="badge badge-primary r-4">
                               <router-link class="text-white" :to="{path:`borrow-loan/${loanCategory._id}`}"> Proceed to borrow loan </router-link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions,mapGetters,mapState} from 'vuex'
export default {
    
    computed:{
        ...mapState({
            loading:state=>state.loading
        }),

        ...mapGetters('loanCategoryStore',['loanCategories'])
    },

    created(){
        if(this.loanCategories.length == 0){
            this.getLoanCategories()
        }

        //alert()
    },

    methods:{
        ...mapActions('loanCategoryStore',['getLoanCategories'])
    }
}
</script>