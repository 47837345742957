<template>
    <div class="row text-white no-gutters no-m shadow">
        <div class="col-lg-4">
            <div class="green  p-40">
                <div class="float-right">
                    <span class="icon icon-male s-48"></span>
                </div>
                <template v-if="loading">...</template>
                <div v-else class="sc-counter s-36">50%</div>
                <h6 class="counter-title">Eligibility</h6>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="sunfollower counter-box p-40">
                <div class="float-right">
                    <span class="icon icon-wallet s-48"></span>
                </div>
                <template v-if="loading">...</template>
                <div v-else class="sc-counter s-36">{{totalOwings > 0 ? 'Owing' : 'Free'}}</div>
                <h6 class="counter-title">Loan status (&#8358;)</h6>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="strawberry counter-box p-40">
                <div class="float-right">
                    <span class="icon icon-add_shopping_cart s-48"></span>
                </div>
                <template v-if="loading">...</template>
                <div v-else class="sc-counter s-36">{{totalLoans}}</div>
                <h6 class="counter-title">Total Loans</h6>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState,mapGetters,mapActions} from 'vuex'
export default {
    props:{
        user:{
            type:Object,
            required:true
        }
    },

    computed:{
        ...mapState({
            loading:state=>state.loading
        }),
        ...mapGetters('loanStore',['totalLoans','totalOwings']),
    },

    created(){
        if(!this.totalLoans || !this.totalOwings){
            this.getTotalLoans(this.user._id)
            this.getTotalOwings(this.user._id)
        }
    },

    methods:{
        ...mapActions('loanStore',['getTotalLoans','getTotalOwings']),
    }
}
</script>