import dashboard from '@/views/user/dashboard'
import profile from '@/views/user/profile'
import nextOfKin from '@/views/user/nextOfKin'
import bank from '@/views/user/banks'
import loanCategory from '@/views/user/loan-categories'
import borrowLoan from '@/views/user/borrowLoan'
import layout from '@/components/Layout'
import security from '@/views/user/security'
import loanHistory from '@/views/user/loanHistory'
import cards from '@/views/user/cards'

export default [
    {
        path:'/user',
        component:layout,
        meta:{
          isAdmin:false,
          requiresAuth:true
        },
        children:[
          {
            path:'dashboard',
            name:'user-dashboard',
            component:dashboard,
            meta:{
                isAdmin:false,
                requiresAuth:true
            }
          },
          {
            path:'profile',
            name:'user-profile',
            component:profile,
            meta:{
                isAdmin:false,
                requiresAuth:true
            },
          },
          {
            path:'next-of-kin',
            name:'next-of-kin',
            component:nextOfKin,
            meta:{
                isAdmin:false,
                requiresAuth:true
            },
          },
          {
            path:'banks',
            name:'user-banks',
            component:bank,
            meta:{
                isAdmin:false,
                requiresAuth:true
            },
          },
          {
            path:'loan-categories',
            name:'user-loan-categories',
            component:loanCategory,
            meta:{
                isAdmin:false,
                requiresAuth:true
            },
          },
          {
            path:'borrow-loan/:id',
            name:'borrow-loan',
            component:borrowLoan,
            meta:{
                isAdmin:false,
                requiresAuth:true
            },
          },
          {
            path:'security',
            name:'user-security',
            component:security,
            meta:{
                isAdmin:false,
                requiresAuth:true
            }
          },
          {
            path:'loan-history',
            name:'user-loan-history',
            component:loanHistory,
            meta:{
                isAdmin:false,
                requiresAuth:true
            }
          },
          {
            path:'cards',
            name:'user-cards',
            component:cards,
            meta:{
                isAdmin:false,
                requiresAuth:true
            }
          }
        ]
    }
]