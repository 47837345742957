<template>
    <div class="table-responsive">
        <table class="table table-hover table-bordered">
            <tr>
                <th>S/n</th>
                <th>User</th>
                <th>Loan Category</th>
                <th>Loan Description</th>
                <th>Status</th>
                <th>Duration</th>
                <th>Payment Authorized</th>
                <th>Pay Count</th>
                <th>Payment Completed</th>
                <th>Date</th>
            </tr> 
                
            <tr v-if="loading">
                <td colspan="10">
                    <b-skeleton-table
                        :rows="5"
                        :columns="10"
                        :table-props="{ bordered: true, striped: true }"
                    ></b-skeleton-table>
                </td>
            </tr>
            <template v-else>
                <tr v-if="loanHistory.length == 0">
                    <td colspan="9" class="text-center">There are no loans</td>
                </tr>
                <tr v-else v-for="loan,i in loanHistory" :key="i">
                    <td>{{++i}}</td>
                    <td>{{loan.user.firstName}}</td>
                    <td>{{loan.loanCategory.type}}</td>
                    <td>{{loan.description}}</td>
                    <td>{{loan.status}}</td>
                    <td>{{loan.loanCategory.duration}} {{loan.loanCategory.durationType}}</td>
                    <td>{{loan.loanActivated}}</td>
                    <td>{{loan.payCount}}</td>
                    <td>{{loan.paymentCompleted}}</td>
                    <td>{{loan.createdAt}}</td>
                </tr>
            </template>                                                                                                                                                                                                                                                                 
        </table>
    </div>
</template>

<script>
    export default{
        props:{
            loanHistory:{
                type:Object,
                required:true
            }
        },
    }
</script>