<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="card" id="incentive">
                    <div class="card-header">
                        Delete loan Category
                    </div>
                    <div class="card-body">
                        <div class="alert alert-info">
                            Are sure to delete this loanCategory - {{loanCategory.type}}
                        </div>
                        <button class="btn btn-danger" @click="deleteLoanCategory()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        loanCategory:{
            type:Object,
            required:true
        }
    },

    methods:{
        deleteLoanCategory(){
            this.$emit('loan-category-delete-confirmed',this.loanCategory._id)
        }
    }
}
</script>