<template>
    <div class="row text-white no-gutters no-m shadow">
        <div class="col-lg-4">
            <div class="green  p-40">
                <div class="float-right">
                    <span class="icon icon-male s-48"></span>
                </div>
                <template v-if="loading">...</template>
                <div v-else class="sc-counter s-36">{{totalUsers}}</div>
                <h6 class="counter-title">Users</h6>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="sunfollower counter-box p-40">
                <div class="float-right">
                    <span class="icon icon-wallet s-48"></span>
                </div>
                <template v-if="loading">...</template>
                <div v-else class="sc-counter s-36">{{totalOwings}}</div>
                <h6 class="counter-title">Owing</h6>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="strawberry counter-box p-40">
                <div class="float-right">
                    <span class="icon icon-add_shopping_cart s-48"></span>
                </div>
                <template v-if="loading">...</template>
                <div v-else class="sc-counter s-36">{{totalLoans}}</div>
                <h6 class="counter-title">Total Loans</h6>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState,mapGetters,mapActions} from 'vuex'
export default {
    // props:{
    //     activity:{
    //         type:Object,
    //         required:true
    //     }
    // },

    computed:{
        ...mapState({
            loading:state=>state.loading
        }),
        ...mapGetters('userStore',['totalUsers']),
        ...mapGetters('loanStore',['totalOwings','totalLoans'])
    },

    created(){
        if(! this.totalUsers){
            this.getTotalUsers()
        }

        if(! this.totalBorrows){
            this.getTotalOwings()
        }

        if(! this.totalLoans){
            this.getTotalLoans()
        }
    },

    methods:{
         ...mapActions('userStore',['getTotalUsers']),
         ...mapActions('loanStore',['getTotalOwings','getTotalLoans'])
    }
}
</script>