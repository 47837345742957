import endPoints from "./endpoints"
import {http} from './axios-config'

export default {

    all(page=1,status=null){
        return http().get(`${endPoints.loans}?page=${page}&status=${status}`)
    },

    userLoans(page=1,status=null){
        return http().get(`${endPoints.loans}/user-loans/?page=${page}&status=${status}`)
    },

    create(data){
        return http().post(`${endPoints.loans}`,data)
    },

    show(id){
        return http().get(`${endPoints.loans}/${id}`)
    },

    delete(id){
        return http().delete(`${endPoints.loans}/${id}`)
    },

    total(){
        return http().get(`${endPoints.loans}/total`)
    },

    userBorrowedLoans(id){
        return http().get(`${endPoints.loans}/${id}/borrowed-loans`)
    },

    borrowedLoans(){
        return http().get(`${endPoints.loans}/borrowed-loans`)
    },

    userRecentPayments(id){
        return http().get(`${endPoints.loans}/${id}/recent-payments`)
    },

    recentPayments(){
        return http().get(`${endPoints.loans}/recent-payments`)
    },

    loanPayments(loanId){
        return http().get(`${endPoints.loans}/${loanId}/loan-payments`)
    },

    totalOwings(id=null){
        return id ? http().get(`${endPoints.loans}/${id}/total-user-owings`) : http().get(`${endPoints.loans}/total-owings`)
    },

    totalLoans(id=null){
        return id ? http().get(`${endPoints.loans}/${id}/total-user-loans`) : http().get(`${endPoints.loans}/total-loans`)
    },

    requestCanclation(id){
        return http().patch(`${endPoints.loans}/${id}/request-canclation`)
    },

    cancleLoan(id){
        return http().patch(`${endPoints.loans}/${id}/cancle-loan`)
    },

    canclationRequests(){
        return http().get(`${endPoints.loans}/canclation-requests`)
    },

    approvalRequests(){
        return http().get(`${endPoints.loans}/approval-requests`)
    },

    approve(id){
        return http().patch(`${endPoints.loans}/${id}/approve-loan`)
    }

}