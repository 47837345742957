<template>
    <div>
        <div class="row">
            <div class="col-md-8">
                <div class="card mb-3">
                    <div class="card-header white">
                        <h6> Loan Categories </h6>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card mb-3">
                    <button class="btn btn-primary" v-b-modal.createLoanCategory>Add</button>
                </div>
            </div>
        </div>
        

        <div class="card" id="profile" role="tabpanel">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                        <tr>
                            <th><strong>S/N</strong></th>
                            <th>Type</th>
                            <th>Duration</th>
                            <th>Duration Type</th>
                            <th>Minimum Value (NGN)</th>
                            <th>Maximum Value (NGN)</th>
                            <th>Interest rate (%)</th>
                            <th>Created At</th>
                            <th><strong>Action</strong></th>
                        </tr> 
                         
                        <tr v-if="loading">
                            <td colspan="9">
                                <b-skeleton-table
                                    :rows="5"
                                    :columns="9"
                                    :table-props="{ bordered: true, striped: true }"
                                ></b-skeleton-table>
                            </td>
                        </tr>
                        <template v-else>
                            <tr v-if="loanCategories.length == 0">
                                <td colspan="9" class="text-center">There are no Loan Categories</td>
                            </tr>
                            <tr v-else v-for="loanCategory,i in loanCategories" :key="i">
                                <td>{{++i}}</td>
                                <td>{{loanCategory.type}}</td>
                                <td>{{loanCategory.duration}}</td>
                                <td>{{loanCategory.durationType}}</td>
                                <td>{{loanCategory.minAmount.toLocaleString('en-US')}}</td>
                                <td>{{loanCategory.maxAmount.toLocaleString('en-US')}}</td>
                                <td>{{loanCategory.interestRate}}</td>
                                <td>{{new Date(loanCategory.createdAt).toDateString()}}</td>
                                <td>
                                    <div class="dropdown">
                                        <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Edit/Delete
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="position:relative">
                                            <a data-id="2" class="dropdown-item btn" @click="setLoanCategory(loanCategory)" v-b-modal.editLoanCategory>Edit</a>
                                            <a data-id="2" class="dropdown-item btn" @click="setLoanCategory(loanCategory)" v-b-modal.deleteLoanCategory>Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>                                                       
                                                                                                                                                                                                                                          
                    </table>
                </div>
            </div>
        </div>

        <modal :modalId="'createLoanCategory'" :modalTitle="'Add Loan Category'" :modalSize="'m'">
            <create-loanCategory @loan-category-created="createLoanCategory"/>
        </modal>

        <modal :modalId="'editLoanCategory'" :modalTitle="'Edit loanCategory'" :modalSize="'lg'">
            <b-card v-if="!loanCategory">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <edit-loanCategory v-else :loanCategory="loanCategory" @loan-category-edited="updateLoanCategory"/>
        </modal>

        <modal :modalId="'deleteloanCategory'" :modalTitle="'Delete loanCategory'">
            <b-card v-if="!loanCategory">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <delete-loanCategory v-else :loanCategory="loanCategory" @loan-category-delete-confirmed="deleteLoanCategory"/>
        </modal>
        
    </div>
</template>

<script>
import modal from '@/components/Modal'
import createLoanCategory from '@/components/admin/loan-category/createLoanCategory'
import editLoanCategory from '@/components/admin/loan-category/editLoanCategory'
import deleteLoanCategory from '@/components/admin/loan-category/deleteLoanCategory'
import {notification} from '@/util/notification'
import {mapActions,mapState,mapGetters} from 'vuex'
export default {
    components:{
        modal,
        editLoanCategory,
        deleteLoanCategory,
        createLoanCategory
    },

    data(){
        return{
            loanCategory:null,
           // rowKey:Math.random()
        }
    },

    computed:{
        ...mapState({
            submitting:state=>state.submitting,
            loading:state=>state.loading
        }),

        ...mapGetters('loanCategoryStore',['loanCategories']),
    },

    created(){
        if(this.loanCategories.length == 0){
            this.getLoanCategories()
        }
    },

    methods:{
        ...mapActions('loanCategoryStore',['create','getLoanCategories','update','delete']),
        createLoanCategory(data){
            //console.log(data.get('loanCategoryName'))
            let loanCategory = {
                type:data.get('type'),
                duration:data.get('duration'),
                durationType:data.get('durationType'),
                minAmount:data.get('minAmount'),
                maxAmount:data.get('maxAmount'),
                interestRate:data.get('interestRate'),
            }
            this.create(loanCategory)
        },

        setLoanCategory(loanCategory){
            this.loanCategory = loanCategory
            console.log('loan Category set'+loanCategory._id)
        },

        updateLoanCategory(data){
            if(!this.loanCategory){
                notification.error("loanCategory not initialized")
            }
            let loanCategory = {
                id:this.loanCategory._id,
                type:data.get('type'),
                duration:data.get('duration'),
                durationType:data.get('durationType'),
                minAmount:data.get('minAmount'),
                maxAmount:data.get('maxAmount'),
                interestRate:data.get('interestRate'),
            }
            this.update({id:this.loanCategory._id,data:loanCategory}).then(res=>{
                console.log(res)
                if(res != undefined && res.status==200){
                    this.$bvModal.hide('editLoanCategory')
                    this.loanCategory = null
                }
            })
            
        },

        deleteLoanCategory(loanCategoryId){
            this.delete(loanCategoryId).then(res=>{
                if(res.status==200){
                    this.$bvModal.hide('deleteLoanCategory')
                    this.loanCategory = null
                }
            })
        },

        // setKey(id,rand=null){
        //     if(!rand){
        //         return id
        //     }
        //     return Math.random()
        // }
    }
    
}
</script>