
export default {

    users(state){
        return state.users
    },

    user(state){
        return (id)=>{
            return state.users.find(ele=>ele.id==id)
        }
    },

    totalUsers(state){
        return state.totalUsers
    },

    profilePhoto(state){
        return state.profilePhoto
    },

    profile(state){
        return state.profile
    },

    income(state){
        return state.income
    },
}