<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="card" id="incentive">
                    <div class="card-header">
                        Update Loan Category
                    </div>
                    <div class="card-body">
                        <form class="form-horizontal form-materia" id="edit-loan-category-form" enctype="multipart/form-data" @submit.prevent="submit()">
                            
                            <div class="form-group">
                                <label class="col-md-12">Type</label>
                                <div class="col-md-12">
                                <input type="text" name="type" :value="loanCategory.type" class="form-control form-control-line">
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <label class="col-md-12">Duration</label>
                                <div class="col-md-12">
                                <input type="text" name="duration" :value="loanCategory.duration" class="form-control form-control-line">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12">Duratio Type</label>
                                <div class="col-md-12">
                                <input type="text" name="durationType" :value="loanCategory.durationType" class="form-control form-control-line">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12">Minimum amount</label>
                                <div class="col-md-12">
                                    <input type="text" name="minAmount" :value="loanCategory.minAmount" class="form-control form-control-line">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12">Maximum amount</label>
                                <div class="col-md-12">
                                    <input type="text" name="maxAmount" :value="loanCategory.maxAmount" class="form-control form-control-line">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12">Interest Rate</label>
                                <div class="col-md-12">
                                    <input type="text" name="interestRate" :value="loanCategory.interestRate" class="form-control form-control-line">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-12">
                                    <button class="btn btn-primary" id="add-incentives">Update loan Category</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components:{
        
    },
    props:{
        loanCategory:{
            type:Object,
            required:true
        }
    },

    methods:{
        submit(){
            let form = document.getElementById('edit-loan-category-form')
            let data = new FormData(form)
            this.$emit('loan-category-edited',data)
        }
    }
}
</script>