<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="card" id="incentive">
                    <div class="card-header">
                        Update User
                    </div>
                    <div class="card-body">
                        <form class="form-horizontal form-materia" id="edit-user-form" enctype="multipart/form-data" @submit.prevent="submit()">
                            
                            <div class="form-group">
                                <label class="col-md-12">First name</label>
                                <div class="col-md-12">
                                <input type="text" name="firstName" :value="user.firstName" class="form-control form-control-line">
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <label class="col-md-12">Last name</label>
                                <div class="col-md-12">
                                <input type="text" name="lastName" :value="user.lastName" class="form-control form-control-line">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12">Email</label>
                                <div class="col-md-12">
                                <input type="text" name="email" :value="user.email" class="form-control form-control-line">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12">Phone</label>
                                <div class="col-md-12">
                                    <input type="text" name="phone" :value="user.phone" class="form-control form-control-line">
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <div class="col-sm-12">
                                    <button class="btn btn-primary" id="add-incentives">Update User</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components:{
        
    },
    props:{
        user:{
            type:Object,
            required:true
        }
    },

    methods:{
        submit(){
            let form = document.getElementById('edit-user-form')
            let data = new FormData(form)
            this.$emit('user-edited',data)
        }
    }
}
</script>