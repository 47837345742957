<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card-header bg-white">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">Full name</strong> <span class="float-right s-12">{{profile.fullName}}</span></li>
                    <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">Email</strong> <span class="float-right s-12">{{profile.email}}</span></li>
                    <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">BVN</strong> <span class="float-right s-12">{{profile.bvn}}</span></li>
                    <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">Marital Status</strong> <span class="float-right s-12">{{profile.maritalStatus}}</span></li>
                    <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">Date of birth</strong> <span class="float-right s-12">{{profile.dob}}</span></li>
                    <li class="list-group-item"><i class="icon icon-phone2 text-info"></i><strong class="s-12 blue-text">Phone</strong> <span class="float-right s-12">{{profile.phone}}</span></li>
                    <li class="list-group-item"><i class="icon icon-user-circle-o text-info"></i> <strong class="s-12 blue-text">Gender</strong> <span class="float-right s-12">{{profile.gender}}</span></li>
                    <li class="list-group-item"><i class="icon icon-level-up  text-info"></i><strong class="s-12 blue-text">Address</strong> <span class="float-right s-12">{{profile.address}}</span></li>
                </ul>
            </div>
            <div class=" my-3" >
                <div class="card" style="background-color:white;">
                    <div class="card-body text-center  p-5 ">
                        <div class=" mb-3 ">
                            <img class="" width="300" :src="photo" alt="profile photo">
                        </div>
                    </div>
                </div>	
            </div>
            <br>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions,mapState } from 'vuex'
    export default{
        props:{
            profile:{
                type:Object,
                required:true
            },
            user:{
                type:Object,
                required:true
            }
        },

        data(){
            return{

            }
        },

        computed:{
            ...mapState({
                loading:state=>state.loading
            }),

            ...mapGetters('authStore',['authUser']),
            //...mapGetters('userStore',['profile'])
            photo(){
            return this.profile ?
             process.env.VUE_APP_UPLOAD_URL+'/'+this.profile.profilePhoto
             : '/assets/img/dummy/u1.png'
        },
        },

        created(){
            
            //this.getProfile(this.user._id)
            
        },

        mathods:{
            ...mapActions('userStore',['getProfile']),
            ...mapActions('authStore',['getUser'])
        }
    }
</script>