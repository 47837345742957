import endPoints from "./endpoints"
import {http} from './axios-config'

export default {

    all(){
        return http().get(endPoints.banks)
    },

    userBanks(userId){
        return http().get(`${endPoints.banks}/${userId}`)
    },

    create(userId,data){
        return http().post(`${endPoints.banks}/${userId}`,data)
    },

    update(id,data){
        return http().put(`${endPoints.banks}/${id}`,data)
    },

    show(id){
        return http().get(`${endPoints.banks}/${id}`)
    },

    delete(id){
        return http().delete(`${endPoints.banks}/${id}`)
    },

}