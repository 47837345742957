<template>
    <div>
        <div class="row">
            <div class="col-md-8">
                <div class="card mb-3">
                    <div class="card-header white">
                        <h6> Loan History </h6>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="dropdown">
                    <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Filter
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="position:relative">
                        <a href="loan-history" data-id="2" class="dropdown-item btn">All</a>
                        <a href="loan-history?status=rejected" data-id="2" class="dropdown-item btn">Cancled Loans</a>
                        <a href="loan-history?status=owing" data-id="2" class="dropdown-item btn">Approved Loans</a>
                        <a href="loan-history?status=paid" data-id="2" class="dropdown-item btn">Previous Loans</a>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="card" id="profile" role="tabpanel">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                        <tr>
                            <th>S/n</th>
                            <th>User</th>
                            <th>Loan Category</th>
                            <th>Loan Description</th>
                            <th>Status</th>
                            <th>Duration</th>
                            <th>Payment Authorized</th>
                            <th>Pay Count</th>
                            <th>Payment Completed</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr> 
                         
                        <tr v-if="loading">
                            <td colspan="10">
                                <b-skeleton-table
                                    :rows="5"
                                    :columns="10"
                                    :table-props="{ bordered: true, striped: true }"
                                ></b-skeleton-table>
                            </td>
                        </tr>
                        <template v-else>
                            <tr v-if="loanHistory.pageOfItems.length == 0">
                                <td colspan="9" class="text-center">There are no loans</td>
                            </tr>
                            <tr v-else v-for="loan,i in loanHistory.pageOfItems" :key="i">
                                <td>{{++i}}</td>
                                <td>{{loan.user.firstName}}</td>
                                <td>{{loan.loanCategory.type}}</td>
                                <td>{{loan.description}}</td>
                                <td>{{loan.status}}</td>
                                <td>{{loan.loanCategory.duration}} {{loan.loanCategory.durationType}}</td>
                                <td>{{loan.loanActivated}}</td>
                                <td>{{loan.payCount}}</td>
                                <td>{{loan.paymentCompleted}}</td>
                                <td>{{new Date(loan.createdAt).toDateString()}}</td>
                                <td>
                                    <div class="dropdown">
                                        <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            View
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="position:relative">
                                            <a v-if="loan.status=='initiated'" data-id="2" class="dropdown-item btn" @click="approveLoan(loan._id)">Approve</a>
                                            <a data-id="2" class="dropdown-item btn" @click="viewPayments(loan)" >View Payments</a>
                                            <a data-id="2" class="dropdown-item btn" @click="setLoan(loan)" v-b-modal.deleteUser>Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>                                                                                                                                                                                                                                                                 
                    </table>
                </div>
            </div>
            <div class="card-footer pb-0 pt-3">
                <ul v-if=" !loading && loanHistory.pager.pages && loanHistory.pager.pages.length" class="pagination">
                    <li :class="{'disabled':loanHistory.pager.currentPage === 1}" class="page-item first-item">
                        <router-link :to="{ query: { page: 1 }}" class="page-link">First</router-link>
                    </li>
                    <li :class="{'disabled':loanHistory.pager.currentPage === 1}" class="page-item previous-item">
                        <router-link :to="{ query: { page: loanHistory.pager.currentPage - 1 }}" class="page-link">Previous</router-link>
                    </li>
                    <li v-for="page in loanHistory.pager.pages" :key="page" :class="{'active':loanHistory.pager.currentPage === page}" class="page-item number-item">
                        <router-link :to="{ query: { page: page }}" class="page-link">{{page}}</router-link>
                    </li>
                    <li :class="{'disabled':loanHistory.pager.currentPage === loanHistory.pager.totalPages}" class="page-item next-item">
                        <router-link :to="{ query: { page: loanHistory.pager.currentPage + 1 }}" class="page-link">Next</router-link>
                    </li>
                    <li :class="{'disabled':loanHistory.pager.currentPage === loanHistory.pager.totalPages}" class="page-item last-item">
                        <router-link :to="{ query: { page: loanHistory.pager.totalPages }}" class="page-link">Last</router-link>
                    </li>
                </ul>
            </div>
        </div>

        <modal :modalId="'viewPayments'" :modalTitle="'View Payments'" :modalSize="'lg'">
            <b-card v-if="loading">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <template v-else>
                <div v-if="loanPayments.length == 0" class="alert alert-info">There are no payments for this loan</div>
                <div v-else class="table-responsive">
                    <table class="table table-hover table-bordered">
                        <tr>
                            <th>s/n</th>
                            <th>Amount payed</th>
                            <th>Reference</th>
                            <th>Successful Payment</th>
                            <th>Confirmed Payment </th>
                            <th>Date</th>
                        </tr> 
                        <tbody>
                            <tr v-for="pay,i in loanPayments" :key="i">
                                <td>{{++i}}</td>
                                <td>{{pay.amount}}</td>
                                <td>{{pay.reference}}</td>
                                <td>{{pay.paymentIsSuccessful}}</td>
                                <td>{{pay.paymentIsConfirmed}}</td>
                                <td>{{new Date(pay.createdAt).toDateString()}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </modal>

    </div>
</template>

<script>
import modal from '@/components/Modal'
//import createUser from '@/components/admin/users/createUser'
//import editUser from '@/components/admin/users/editUser'
//import deleteUser from '@/components/admin/users/deleteUser'
//import {notification} from '@/util/notification'
import {mapActions,mapState,mapGetters} from 'vuex'
export default {
    components:{
        modal,
        
    },

    data(){
        return{
            loan:null,
           //sn:this.$route.query.page * 
        }
    },

    computed:{
        ...mapState({
            submitting:state=>state.submitting,
            loading:state=>state.loading
        }),

        ...mapGetters('loanStore',['loanHistory','loanPayments']),
    },

    created(){
        let data = {page:this.$route.query.page || 1,status:this.$route.query.status}
        this.all(data)
    },

    watch: {
        '$route.query.page': {
            immediate: true,
            handler(page) {
                page = parseInt(page) || 1;
                let data = {page:this.$route.query.page || 1,status:this.$route.query.status}
                if ( this.loanHistory && page !== 0) {
                    this.all(data)
                }
            }
        }
    },

    methods:{
        ...mapActions('loanStore',['all','getLoanPayments','requestCanclation','approve']),
        
        setLoan(loan){
            this.loan = loan
            console.log('loan set'+loan._id)
        },

        viewPayments(loan){
            this.loan = null
            this.$bvModal.show('viewPayments')
            this.setLoan(loan)
            this.getLoanPayments(loan._id)
        },

        cancleLoan(id){
            this.requestCanclation(id)
        },

        approveLoan(id){
            this.approve(id)
        }
    }
    
}
</script>