<template>
    <div>
        <div class="row">
            <div class="col-md-8">
                <div class="card mb-3">
                    <div class="card-header white">
                        <h6> Cards </h6>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card mb-3">
                    <!-- <button class="btn btn-primary" v-b-modal.createBank>Add</button> -->
                    <button v-b-modal.addCard class="btn btn-primary">Add</button>
                </div>
            </div>
        </div>
        

        <div class="card" id="profile" role="tabpanel">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                        <tr>
                            <th><strong>S/N</strong></th>
                            <th>Card Name</th>
                            <th>Card Type</th>
                            <th>Bank Name</th>
                            <th>Card Number</th>
                            <th>Bin</th>
                            <th><strong>Action</strong></th>
                        </tr> 
                         
                        <tr v-if="loading">
                            <td colspan="7">
                                <b-skeleton-table
                                    :rows="5"
                                    :columns="7"
                                    :table-props="{ bordered: true, striped: true }"
                                ></b-skeleton-table>
                            </td>
                        </tr>
                        <template v-else>
                            <tr v-if="userCards.length == 0">
                                <td colspan="7">There are no cards</td>
                            </tr>
                                <tr v-else v-for="card,i in userCards" :key="i">
                                <td>{{++i}}</td>
                                <td>{{card.cardObject.account_name ? card.cardObject.account_name : 'Test name' }}</td>
                                <td>{{card.cardObject.card_type}}</td>
                                <td>{{card.cardObject.bank}}</td>
                                <td>***********{{card.cardObject.last4}}</td>
                                <td>{{card.cardObject.bin}}</td>
                                <td>
                                    <div class="dropdown">
                                        <!-- <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Edit/Delete
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="position:relative">
                                            <a data-id="2" class="dropdown-item btn" @click="setBank(bank)" v-b-modal.deleteCard>Delete</a>
                                        </div> -->
                                    </div>
                                </td>
                            </tr>
                        </template>                                                       
                                                                                                                                                                                                                                          
                    </table>
                </div>
            </div>
        </div>

        <modal :modalId="'addCard'" :modalTitle="'Add Card'" :modalSize="'lg'">
            <add-card @card-added="cardAdded()"/>
        </modal>

        <!-- <modal :modalId="'deleteCard'" :modalTitle="'Add Card'" :modalSize="'lg'">
            <add-card @deleting-card="deletingCard()"/>
        </modal> -->

        <!-- <modal :modalId="'editBank'" :modalTitle="'Edit bank'" :modalSize="'lg'">
            <b-card v-if="!bank">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <edit-bank v-else :bank="bank" @bank-edited="updateBank"/>
        </modal> -->

        <!-- <modal :modalId="'deleteBank'" :modalTitle="'Delete bank'">
            <b-card v-if="!bank">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <delete-bank v-else :bank="bank" @bank-delete-confirmed="deleteBank"/>
        </modal> -->
        
    </div>
</template>

<script>
import modal from '@/components/Modal'
//import createCard from '@/components/banks/createCard'
import addCard from '@/components/cards/addCard'
//import editBank from '@/components/banks/editBank'
//import deleteBank from '@/components/banks/deleteBank'
//import {notification} from '@/util/notification'
import {mapActions,mapState,mapGetters} from 'vuex'
import vm from '../../main'
//import Connect from '@mono.co/connect.js'
//import mono from '../../api/mono'
export default {
    components:{
        modal,
        //editBank,
        //deleteBank,
       addCard
        //createCard
    },

    data(){
        return{
            bank:null,
           // rowKey:Math.random()
        }
    },

    computed:{
        ...mapState({
            submitting:state=>state.submitting,
            loading:state=>state.loading
        }),

        ...mapGetters('cardStore',['userCards']),
        ...mapGetters('authStore',['authUser']),
    },

    created(){
         if(Object.entries(this.authUser).length == 0){
            this.getUser().then(function(res){
                if(res.status == 200){
                    //alert(res.data._id)
                   vm.$store.dispatch('cardStore/getUserCards',res.data._id)
                   //this.getUserBanks(res.data._id)
                }
            })
         }else{
            this.getUserCards(this.authUser._id)
         }
        
    },

    methods:{
        ...mapActions('cardStore',['create','getUserCards','update','delete']),
        ...mapActions('authStore',['getUser']),

        cardAdded(){
            vm.$store.dispatch('cardStore/getUserCards',this.authUser._id)
            this.$bvModal.hide('addCard')
        },

        deletingCard(){
            this.delete()
        }
    }
    
}
</script>