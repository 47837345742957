<template>
    <div>
        <!-- <div class="row">
            <div class="col-md-8">
                <div class="card mb-3">
                    <div class="card-header white">
                        <h6> Cards </h6>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-md-12">
                <form class="form-horizontal form-materia" id="create-card-form" @submit.prevent="submit()">     
                    <div class="form-group">
                        <label class="col-md-12">Email</label>
                        <div class="col-md-12">
                        <input type="text" :value="authUser.email" readonly name="email" required class="form-control form-control-line">
                        </div>
                    </div>
                    
                    <div class="form-group">
                        <div v-if="loading==false || amount !== null" class="col-sm-12">
                            <paystack
                                :amount="amount"
                                :email="authUser.email"
                                :paystackkey=payStackPK               
                                :reference=reference
                                :callback="processPayment"
                                :close="close()"
                                >
                                Authorize Card
                            </paystack>
                            <!-- <button class="btn btn-primary" id="add-incentives">Add Card</button> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
        
    </div>
</template>

<script>
//import modal from '@/components/Modal'

import {notification} from '@/util/notification'
import {mapActions,mapState,mapGetters} from 'vuex'
//import vm from '../../main'
import paystack from 'vue-paystack';
import api from '../../api/payments'
export default {
    components:{
        //modal,
        //editBank,
        //deleteBank,
        //createBank
        paystack
    },

    data(){
        return{
           form:{
            name:null,
            email:null
           },
           amount:null
        }
    },

    computed:{
        ...mapState({
            submitting:state=>state.submitting,
            loading:state=>state.loading
        }),

        //...mapGetters('bankStore',['userBanks']),
        ...mapGetters('authStore',['authUser']),

        reference() {
            let text = "";
            let possible =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (let i = 0; i < 10; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            return text;
        },

        payStackPK(){
            return process.env.VUE_APP_PAYSTACK_PUBLIC_KEY
        }
    },

    created(){
        //  if(Object.entries(this.authUser).length == 0){
        //     this.getUser().then(function(res){
        //         if(res.status == 200){
        //             //alert(res.data._id)
        //            vm.$store.dispatch('bankStore/getUserBanks',res.data._id)
        //            //this.getUserBanks(res.data._id)
        //         }
        //     })
        //  }else{
        //     this.getUserBanks(this.authUser._id)
        //  }

        if(!this.amount){
            this.getChargeAmount()
        }
        
    },

    methods:{
        ...mapActions('bankStore',['create','getUserBanks','update','delete']),
        ...mapActions('authStore',['getUser']),

        processPayment: function() {
            api.verify(this.reference).then(res=>{
                if(res.status == 200){
                    notification.success("Payment recieved")
                }else{
                    notification.error("Unable to verify payment")
                }
                this.$emit('card-added')
            }).catch(err=>{
                console.log(err)
                notification.error("An error occured while processing payment")
            });
        },

        getChargeAmount(){
            var that = this
            api.authorizationCharge().then(res=>{
                if(res.status == 200){
                    that.amount = res.data.amount * 100
                    //alert(that.amount)
                }else{
                    notification.error("Error, An error occured while fetching charge amount")
                }
            }).catch(err=>{
                console.log(err)
                notification.error("Error, Unable to fetch charge amount")
            })
        },

        close: () => {
            console.log("You closed checkout page")
        }

        
    }
    
}
</script>