import api from '../../api/loans'
import toastr from 'toastr'
//import {commit} from 'vuex'

export default {

    async all({commit},data){
        try {
            commit('loading',null,{root:true})
            let res = await api.all(data.page,data.status)
            if(res.status == 200){
                commit('loanHistory',res.data)
            }else{
                toastr.error('Error, unable to fetch loans')
            }
            commit('loaded',null,{root:true})
        } catch (err) {
            LogError(commit,err,'loaded')
        }
    },

    async getUserLoans({commit},data){
        try {
            commit('loading',null,{root:true})
            let res = await api.userLoans(data.page,data.status)
            if(res.status == 200){
                commit('loanHistory',res.data)
            }else{
                toastr.error('Error, unable to fetch loans')
            }
            commit('loaded',null,{root:true})
        } catch (err) {
            LogError(commit,err,'loaded')
        }
    },

    async create({commit},data){
        var res;
        try {
            commit('submitting',null,{root:true})
            res = await api.create(data)
            if(res.status==200){
                toastr.success("Loan initiated successfully")
                commit('loan',data)
            }else{
                toastr.error(res.data.message)
            }
            commit('submitted',null,{root:true})
            return res
        } catch (err) {
            LogError(commit,err,'submitted')
        }
    },

    async getBorrowedLoans({commit},userId){
        var res;
        try {
            commit('loading',null,{root:true})
            //alert(userId )
            res = (typeof userId == 'string') ? await api.userBorrowedLoans(userId) : await api.borrowedLoans()
            if(res.status==200){
                toastr.success("Recent Loans fetched successfully")
                commit('borrowedLoans',res.data)
            }else{
                //console.log(res.status)
                toastr.error(res.data.message)
            }
            commit('loaded',null,{root:true})
            return res
        } catch (err) {
            LogError(commit,err,'loaded')
        }
    },

    async getRecentPayments({commit},userId){
        var res;
        try {
            commit('loading',null,{root:true})
            res = (userId) ? await api.userRecentPayments(userId) : await api.recentPayments()
            if(res.status==200){
                toastr.success("recent payment fetched successfully")
                commit('recentPayments',res.data)
            }else{
                toastr.error(res.data.message)
            }
            commit('loaded',null,{root:true})
            return res
        } catch (err) {
            LogError(commit,err,'loaded')
        }
    },

    async getLoanPayments({commit},id){
        try {
            commit('loading',null,{root:true})
            let res = await api.loanPayments(id)
            if(res.status == 200){
                commit('loanPayments',res.data)
            }else{
                toastr.error('Error, unable to fetch loan payments')
            }
            commit('loaded',null,{root:true})
        } catch (err) {
            LogError(commit,err,'loaded')
        }
    },

    async getTotalOwings({commit},id){
        try {
            commit('loading',null,{root:true})
            let res = await api.totalOwings(id)
            if(res.status == 200){
                commit('totalOwings',res.data.total)
            }else{
                toastr.error('Error, unable to fetch total owings')
            }
            commit('loaded',null,{root:true})
        } catch (err) {
            LogError(commit,err,'loaded')
        }
    },

    async getTotalLoans({commit},id){
        try {
            commit('loading',null,{root:true})
            let res = await api.totalLoans(id)
            if(res.status == 200){
                commit('totalLoans',res.data.total)
            }else{
                toastr.error('Error, unable to fetch total loans')
            }
            commit('loaded',null,{root:true})
        } catch (err) {
            LogError(commit,err,'loaded')
        }
    },

    async requestCanclation({commit},id){
        try {
            commit('submitting',null,{root:true})
            let res = await api.requestCanclation(id)
            if(res.status == 200){
                toastr.success('Canclation requested successfully')
            }else{
                toastr.error('Error, unable to requst for canclation')
            }
            commit('submitted',null,{root:true})
        } catch (err) {
            LogError(commit,err,'submitted')
        }
    },

    async cancleLoan({commit},id){
        try {
            commit('submitting',null,{root:true})
            let res = await api.cancleLoan(id)
            if(res.status == 200){
                location.reload()
            }else{
                toastr.error('Error, unable to cancle loan')
            }
            commit('submitted',null,{root:true})
        } catch (err) {
            LogError(commit,err,'submitted')
        }
    },

    async approveLoan({commit},id){
        try {
            commit('submitting',null,{root:true})
            let res = await api.approve(id)
            if(res.status == 200){
                //location.reload()
                toastr.error('Loan approved successfully')
            }else{
                toastr.error('Error, unable to approve loan')
            }
            commit('submitted',null,{root:true})
        } catch (err) {
            LogError(commit,err,'submitted')
        }
    },

    async getCanclationRequests({commit}){
        try {
            commit('loading',null,{root:true})
            let res = await api.canclationRequests()
            if(res.status == 200){
                commit('canclationRequests',res.data)
            }else{
                toastr.error('Error, unable to requst for canclation')
            }
            commit('loaded',null,{root:true})
        } catch (err) {
            LogError(commit,err,'loaded')
        }
    },

    async getApprovalRequests({commit}){
        try {
            commit('loading',null,{root:true})
            let res = await api.approvalRequests()
            if(res.status == 200){
                commit('approvalRequests',res.data)
            }else{
                toastr.error('Error, unable fetch pending loan approvals')
            }
            commit('loaded',null,{root:true})
        } catch (err) {
            LogError(commit,err,'loaded')
        }
    },
}

const LogError = (commit,err,commitType)=>{
    if(err.response !== undefined){
        let {response} = err
        //console.log(err.response)
        response.status==422 ? toastr.error(response.data.message) : (response.data.message != undefined) 
        ? toastr.error(response.data.message) : toastr.error('An error occred')
    }else{
        console.log(err)
        toastr.error("Response not found")
    }
    commit(commitType,null,{root:true})
}