<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card-header bg-white">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">Full name</strong> <span class="float-right s-12">{{fullName}}</span></li>
                    <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">Email</strong> <span class="float-right s-12">{{user.email}}</span></li>
                    <li class="list-group-item"><i class="icon icon-phone2 text-info"></i><strong class="s-12 blue-text">Phone</strong> <span class="float-right s-12">{{user.phone}}</span></li>
                    <li class="list-group-item"><i class="icon icon-user-circle-o text-info"></i> <strong class="s-12 blue-text">Gender</strong> <span class="float-right s-12">{{user.gender}}</span></li>
                    <li class="list-group-item"><i class="icon icon-level-up  text-info"></i><strong class="s-12 blue-text">state</strong> <span class="float-right s-12">{{user.state}}</span></li>
                    <li class="list-group-item"><i class="icon icon-level-up  text-info"></i><strong class="s-12 blue-text">Address</strong> <span class="float-right s-12">{{user.address}}</span></li>
                </ul>
            </div>
           
            <br>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions,mapState } from 'vuex'
    export default{
        props:{
            user:{
                type:Object,
                required:true
            },
        },

        data(){
            return{

            }
        },

        computed:{
            ...mapState({
                loading:state=>state.loading
            }),

            ...mapGetters('authStore',['authUser']),
            //...mapGetters('userStore',['user'])
            photo(){
                return this.user ?
                process.env.VUE_APP_UPLOAD_URL+'/'+this.user.userPhoto
                : '/assets/img/dummy/u1.png'
            },

            fullName(){
                return this.user.firstName+" "+this.user.lastName
            }
        },

        created(){
            
            //this.getuser(this.user._id)
            
        },

        mathods:{
            ...mapActions('userStore',['getuser']),
            ...mapActions('authStore',['getUser'])
        }
    }
</script>