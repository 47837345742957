export default {

    loan(state){
        return state.loan
    },

    borrowedLoans(state){
        return state.borrowedLoans
    },

    recentPayments(state){
        return state.recentPayments
    },

    loanHistory(state){
        return state.loanHistory
    },

    loanPayments(state){
        return state.loanPayments
    },

    totalLoans(state){
        return state.totalLoans
    },

    totalOwings(state){
        return state.totalOwings
    },

    canclationRequests(state){
        return state.canclationRequests
    },

    approvalRequests(state){
        return state.approvalRequests
    },

}
