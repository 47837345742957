<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card-header bg-white">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">Monthly Income</strong> <span class="float-right s-12">{{income.monthly}}</span></li>
                    <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">Yearly Income</strong> <span class="float-right s-12">{{income.yearlyIncome}}</span></li>
                    <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">Income Sources</strong> <span class="float-right s-12">{{income.incomeSources}}</span></li>
                    <li class="list-group-item"><i class="icon icon-person text-info"></i><strong class="s-12 blue-text">Average Income</strong> <span class="float-right s-12">{{income.averageIncome}}</span></li>
                </ul>
            </div>
            <br>
        </div>
    </div>
</template>

<script>
import { mapGetters,mapState } from 'vuex'
    export default{
        props:{
            income:{
                type:Object,
                required:true
            }
        },
        data(){
            return{

            }
        },

        computed:{
            ...mapState({
                loading:state=>state.loading
            }),

            ...mapGetters('authStore',['authUser']),
           
        },

        created(){
            // var that = this
            // if(Object.entries(this.authUser).length == 0){
            //     this.getUser().then(res=>{
            //         if(res.status == 200){
            //             that.getIncome(that.authUser._id)
            //         }
            //     })
            // }else{
            //     this.getIncome(this.authUser._id)
            // }
        },

        mathods:{
            // ...mapActions('userStore',['getIncome']),
            // ...mapActions('authStore',['getUser'])
        }
    }
</script>