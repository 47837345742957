import api from '@/api/users'
import {notification} from '@/util/notification'

export default{

    async getUsers({commit}){
        try {
            commit('loading',null,{root:true})
            const res = await api.all()
            if(res.status==200){
                commit('users',res.data)
            }else{
                notification.error(res.message)
            }
            commit('loaded',null,{root:true})
        } catch (error) {
            LogError(commit,error,'loaded')
        }
    },

    async getUser({commit},id){
        try {
            commit('loading',null,{root:true})
            const res = await api.show(id)
            commit('user',res.data)
            commit('loaded',null,{root:true})
            //alert(res.data._id)
            return res
        } catch (error) {
            LogError(commit,error,'loaded')
        }
    },

    async getTotalUsers({commit}){
        try {
            commit('loading',null,{root:true})
            const res = await api.total()
            processResponse(commit,res,'totalUsers')
            commit('loaded',null,{root:true})
        } catch (error) {
            LogError(commit,error,'loaded')
        }
    },

    async create({commit},data){
        try {
            commit('submitting',null,{root:true})
            const res = await api.create(data)
            processResponse(commit,res,'user','storeUser created successfully')
            commit('submitted',null,{root:true})
            return res
        } catch (error) {
            LogError(commit,error,'submitted')
        }
    },

    async update({commit},data){
        try {
            commit('submitting',null,{root:true})
            const res = await api.update(data.id,data.data)
            processResponse(commit,res,'user','user updated successfully')
            commit('submitted',null,{root:true})
            return res
        } catch (error) {
            LogError(commit,error,'submitted')
        }
    },

    async delete({commit},id){
        try {
            commit('submitting',null,{root:true})
            const res = await api.delete(id)
            processResponse(commit,res,'submitted',"User deleted successfully")
        } catch (error) {
            LogError(commit,error,'submitted')
        }
    },

    async updateProfilePhoto({commit},data){
        try {
            commit('submitting',null,{root:true})
            const res = await api.updateProfilePhoto(data.id,data.data)
            processResponse(commit,res,'user','user updated successfully')
            commit('submitted',null,{root:true})
            return res
        } catch (error) {
            LogError(commit,error,'submitted')
        }
    },

    async getProfilePhoto({commit},id){
        try {
            commit('loading',null,{root:true})
            const res = await api.showProfilePhoto(id,)
            commit('profilePhoto',res.data)
            //processResponse(commit,res,'profilePhoto','')
            commit('loaded',null,{root:true})
            return res
        } catch (error) {
            LogError(commit,error,'loaded')
        }
    },

    async getProfile({commit},id){
        try {
            commit('loading',null,{root:true})
            const res = await api.showProfile(id)
            commit('profile',res.data)
            //alert(res.data._id)
            //processResponse(commit,res,'profilePhoto','')
            commit('loaded',null,{root:true})
            return res
        } catch (error) {
            LogError(commit,error,'loaded')
        }
    },

    async getIncome({commit},id){
        try {
            commit('loading',null,{root:true})
            const res = await api.showIncome(id)
            commit('income',res.data)
            //processResponse(commit,res,'profilePhoto','')
            commit('loaded',null,{root:true})
            return res
        } catch (error) {
            LogError(commit,error,'loaded')
        }
    },

}

const LogError = (commit,err,commitType)=>{
    if(err.response !== undefined){
        let {response} = err
        //console.log(err.response)
        response.status==422 ? notification.error(response.data.message) : (response.data.message != undefined) 
        ? notification.error(response.data.message) : notification.error('An error occred')
    }else{
        console.log(err)
        notification.error("Response not found")
    }
    commit(commitType,null,{root:true})
}

const processResponse = (commit,res,commitType,successMsg=null)=>{
    if(res.status !== undefined){
        let {status,message,data} = res
        if(status==200){
           if(commit) commit(commitType,data)
           if(successMsg) notification.success(successMsg)
        }else{
            notification.error(message)
        }
    }else{
        notification.error("response not found")
    }
}