import actions from '../store/actions/loan-actions'
import getters from '../store/getters/loan-getters'
import mutations from '../store/mutations/loan-mutations'

export default{
    namespaced:true,
    state(){
        return{
            loan:null,
            borrowedLoans:[],
            recentPayments:[],
            loanHistory:{},
            loanPayments:[],
            totalLoans:null,
            totalOwings:null,
            canclationRequests:[],
            approvalRequests:[]
        }
    },
    actions:{
        ...actions
    },
    getters:{
        ...getters
    },
    mutations:{
        ...mutations 
    }
}