<template>
    <div>
        <div class="row">
            <div class="col-md-8">
                <div class="card mb-3">
                    <div class="card-header white">
                        <h6> Users </h6>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card mb-3">
                    <button class="btn btn-primary" v-b-modal.createUser>Add</button>
                </div>
            </div>
        </div>
        

        <div class="card" id="profile" role="tabpanel">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                        <tr>
                            <th><strong>S/N</strong></th>
                            <th>First name</th>
                            <th>Last name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Created At</th>
                            <th><strong>Action</strong></th>
                        </tr> 
                         
                        <tr v-if="loading">
                            <td colspan="7">
                                <b-skeleton-table
                                    :rows="5"
                                    :columns="7"
                                    :table-props="{ bordered: true, striped: true }"
                                ></b-skeleton-table>
                            </td>
                        </tr>
                        <template v-else>
                            <tr v-if="users.length == 0">
                                <td colspan="9" class="text-center">There are no users</td>
                            </tr>
                            <tr v-else v-for="user,i in users" :key="i">
                                <td>{{++i}}</td>
                                <td>{{user.firstName}}</td>
                                <td>{{user.lastName}}</td>
                                <td>{{user.email}}</td>
                                <td>{{user.phone}}</td>
                                <td>{{new Date(user.createdAt).toDateString()}}</td>
                                <td>
                                    <div class="dropdown">
                                        <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Edit/Delete
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="position:relative">
                                            <a data-id="2" class="dropdown-item btn" @click="setUser(user)" v-b-modal.editUser>Edit</a>
                                            <a data-id="2" class="dropdown-item btn" @click="showUser(user)">View User details</a>
                                            <a data-id="2" class="dropdown-item btn" @click="showProfile(user)">View Profile</a>
                                            <a data-id="2" class="dropdown-item btn" @click="showIncome(user)">View Income</a>
                                            <a data-id="2" class="dropdown-item btn" @click="showLoans(user)">View Loan History</a>
                                            <!-- <a data-id="2" class="dropdown-item btn" @click="">Fetch Statement</a> -->
                                            <a data-id="2" class="dropdown-item btn" @click="setUser(user)" v-b-modal.deleteUser>Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>                                                       
                                                                                                                                                                                                                                          
                    </table>
                </div>
            </div>
        </div>

        <modal :modalId="'createUser'" :modalTitle="'Add User'" :modalSize="'m'">
            <create-user @user-created="createUser"/>
        </modal>

        <modal :modalId="'editUser'" :modalTitle="'Edit user'" :modalSize="'lg'">
            <b-card v-if="!user">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <edit-user v-else :user="user" @user-edited="updateUser"/>
        </modal>

        <modal :modalId="'deleteUser'" :modalTitle="'Delete user'">
            <b-card v-if="!user">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <delete-user v-else :user="user" @user-delete-confirmed="deleteUser"/>
        </modal>

        <modal :modalId="'viewProfile'" :modalTitle="'User Profile'">
            <b-card v-if="!user">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
             <template v-else>
                <div v-if="!profile">
                    <p>There is no profile for this user</p>
                </div>
                <profile v-else :profile="profile"/>
             </template>
        </modal>

        <modal :modalId="'viewUser'" :modalTitle="'User Details'">
            <b-card v-if="!user">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
             <template v-else>
                <user-details :user="user"/>
             </template>
        </modal>

        <modal :modalId="'viewIncome'" :modalTitle="'User Income'">
            <b-card v-if="!user">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <template v-else>
                <div v-if="!income">
                    <p>There are no income for this user</p>
                </div>
                <income v-else :income="income"/>
            </template>
            
        </modal>

        <modal :modalId="'viewLoans'" :modalTitle="'User Loans'" :modalSize="'lg'">
            <b-card v-if="!user">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <template v-else>
                <div v-if="!loans">
                    <p>There are no loans for this user</p>
                </div>
                <loans v-else :loanHistory="loans"/>
            </template>
        </modal>
    </div>
</template>

<script>
import modal from '@/components/Modal'
import createUser from '@/components/admin/users/createUser'
import editUser from '@/components/admin/users/editUser'
import deleteUser from '@/components/admin/users/deleteUser'
import profile from '@/components/admin/users/profile'
import userDetails from '@/components/admin/users/userDetails'
import income from '@/components/admin/users/income'
import loans from '@/components/admin/users/loanHistory'
import {notification} from '@/util/notification'
import {mapActions,mapState,mapGetters} from 'vuex'
export default {
    components:{
        modal,
        editUser,
        deleteUser,
        createUser,
        profile,
        income,
        loans,
        userDetails
    },

    data(){
        return{
            user:null,
            profile:null,
            income:null,
            loans:null
        }
    },

    computed:{
        ...mapState({
            submitting:state=>state.submitting,
            loading:state=>state.loading
        }),

        ...mapGetters('userStore',['users']),
    },

    created(){
        if(this.users.length == 0){
            this.getUsers()
        }
    },

    methods:{
        ...mapActions('userStore',['create','getUsers','update',
        'delete','getProfile','getUser','getIncome']),
        ...mapActions('loanStore',['getBorrowedLoans']),
        createUser(data){
            //console.log(data.get('UserName'))
            let user = {
                firstName:data.get('firstName'),
                lastName:data.get('lastName'),
                email:data.get('email'),
                phone:data.get('phone'),
                password:'password',
            }
            this.create(user)
        },

        setUser(user){
            this.user = user
            console.log('user set'+user._id)
        },

        updateUser(data){
            if(!this.user){
                notification.error("User not initialized")
            }
            let user = {
                id:this.user._id,
                firstName:data.get('firstName'),
                lastName:data.get('lastName'),
                email:data.get('email'),
                phone:data.get('phone'),
            }
            this.update(user).then(res=>{
                console.log(res)
                if(res != undefined && res.status==200){
                    this.$bvModal.hide('editUser')
                    this.user = null
                }
            })
            
        },

        deleteUser(userId){
            this.delete(userId).then(res=>{
                if(res.status==200){
                    this.$bvModal.hide('deleteUser')
                    this.user = null
                }
            })
        },

        showProfile(user){
            var that = this
            this.user = user
            this.$bvModal.show('viewProfile')
            this.getProfile(user._id).then(res=>{
                //alert(res.data._id)
                that.profile = res.data
            })
        },

        showUser(user){
            this.user = user
            this.$bvModal.show('viewUser')
        },

        showIncome(user){
            var that = this
            this.user = user
            this.$bvModal.show('viewIncome')
            this.getIncome(user._id).then(res=>{
                //alert(res.data._id)
                that.income = res.data
            })
        },

        showLoans(user){
            var that = this
            this.user = user
            this.$bvModal.show('viewLoans')
            this.getBorrowedLoans(user._id).then(res=>{
                //alert(res.data._id)
                that.loans =  res.data.slice(1,10)
            })
        },
    }
    
}
</script>