<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-3">
                    <div class="card-header white">
                        <h6> Pending Approval Requests </h6>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="card" id="profile" role="tabpanel">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                        <tr>
                            <th>S/n</th>
                            <th>User</th>
                            <th>Loan Category</th>
                            <th>Amount</th>
                            <th>Recurring Payment</th>
                            <th>Loan Description</th>
                            <th>Duration</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr> 
                         
                        <tr v-if="loading">
                            <td colspan="10">
                                <b-skeleton-table
                                    :rows="5"
                                    :columns="10"
                                    :table-props="{ bordered: true, striped: true }"
                                ></b-skeleton-table>
                            </td>
                        </tr>
                        <template v-else>
                            <tr v-if="approvalRequests.length == 0">
                                <td colspan="9" class="text-center">There are no approval requests</td>
                            </tr>
                            <tr v-else v-for="loan,i in approvalRequests" :key="i">
                                <td>{{++i}}</td>
                                <td>{{loan.user.firstName}}</td>
                                <td>{{loan.loanCategory.type}}</td>
                                <td>{{loan.loanAmount.toLocaleString('en-US')}}</td>
                                <td>{{loan.recurringPaymentAmount.toLocaleString('en-US')}}</td>
                                <td>{{loan.description}}</td>
                                <td>{{loan.loanCategory.duration}} {{loan.loanCategory.durationType}}</td>
                                <td>{{new Date(loan.createdAt).toDateString()}}</td>
                                <td>
                                    <div class="dropdown">
                                        <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Cancle
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="position:relative">
                                            <a data-id="2" class="dropdown-item btn" @click="confirmApproval(loan)" v-b-modal.approveLoan>Approve Loan</a>
                                            <a data-id="2" class="dropdown-item btn" @click="confirmRejection(loan)" v-b-modal.approveLoan>Reject Loan</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>                                                                                                                                                                                                                                                                 
                    </table>
                </div>
            </div>
        </div>

         <modal :modalId="'confirmApproval'" :modalTitle="'Approve Loan'" :modalSize="'sm'">
            <div>
                <button class="btn alert alert-danger" @click="loanApproved()">Yes</button>
                Please confirm to approve the loan
            </div>
         </modal>

         <modal :modalId="'confirmRejection'" :modalTitle="'Reject Loan'" :modalSize="'sm'">
            <div>
                <button class="btn alert alert-danger" @click="loanRejected()">Yes</button>
                Please confirm to reject the loan
            </div>
         </modal>
    </div>
</template>

<script>
//import createUser from '@/components/admin/users/createUser'
//import editUser from '@/components/admin/users/editUser'
//import deleteUser from '@/components/admin/users/deleteUser'
//import {notification} from '@/util/notification'
import modal from '@/components/Modal'
import {mapActions,mapState,mapGetters} from 'vuex'
export default {
    components:{
        modal
    },

    data(){
        return{
            loan:null,
           //sn:this.$route.query.page * 
        }
    },

    computed:{
        ...mapState({
            submitting:state=>state.submitting,
            loading:state=>state.loading
        }),

        ...mapGetters('loanStore',['approvalRequests']),
    },

    created(){
        if(this.approvalRequests.length == 0){
            this.getApprovalRequests()
        }
    },

   

    methods:{
        ...mapActions('loanStore',['getApprovalRequests','approveLoan','cancleLoan']),
        
        confirmApproval(loan){
            this.loan = loan
            this.$bvModal.show('confirmApproval')
        },

        confirmRejection(loan){
            this.loan = loan
            this.$bvModal.show('confirmRejection')
        },

        loanApproved(){
            this.approveLoan(this.loan._id)
        },

        loanRejected(){
            this.cancleLoan(this.loan._id)
        }
    }
    
}
</script>