export default {
    
    loanCategories(state,data){
        state.loanCategories = data
    },

    loanCategory(state,data){
        state.loanCategory = data
    },

    storeLoanCategory(state,data){
        state.loanCategories.push(data)
    },
    
    updateLoanCategory(state,data){
        let categories = state.loanCategories
        let index = categories.findIndex(ele=>ele._id==data._id)
        if(index == -1){
            throw new Error("loanCategory not found on client")
        }
        categories.splice(index,1,data)
    },

    deleteLoanCategory(state,data){
        let categories = state.loanCategories
        let index = categories.findIndex(ele=>ele.id==data.id)
        categories.splice(index,1)
    }
}