<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-3">
                    <div class="card-header white">
                        <h6> Apply for Loans &nbsp; &nbsp; -><router-link :to="{name:'user-loan-categories'}">Back</router-link></h6>
                        <span class=""></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6" v-if="!loading">
                <div class="card">
                    <img class="card-img-top" src="/assets/img/demo/portfolio/p4.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">{{loanCategory.type}}</h5>
                        <p class="card-text">
                            {{loanCategory.description}}
                        </p>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">Min amount
                            <span class="float-right badge badge-primary r-4">{{loanCategory.minAmount ? loanCategory.minAmount.toLocaleString('en-US') : loanCategory.minAmount}}</span>
                        </li>
                        <li class="list-group-item">Max Amount
                            <span class="float-right badge badge-primary r-4">{{loanCategory.maxAmount ? loanCategory.maxAmount.toLocaleString('en-US') : loanCategory.maxAmount}}</span>
                        </li>
                        <li class="list-group-item">Duration
                            <span class="float-right badge badge-primary r-4">{{loanCategory.duration}} {{loanCategory.durationType}}s</span>
                        </li>
                        <li class="list-group-item">Interest Rate
                            <span class="float-right badge badge-primary r-4">{{loanCategory.interestRate}} %</span>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="card text-center">
                    <div class="card-header">
                        Loan Analysis
                    </div>
                    <div class="card-body"> 
                        
                        <h5 class="card-title">{{loanCategory.type}}</h5>
                        <p class="card-text">{{loanCategory.description}}</p>
                        <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
            </div> -->
            
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">
                        Apply for Loan
                    </div>
                    <div class="card-body"> 
                        <form @submit.prevent="submit()">
                            <div class="form-row">
                                <div class="form-group col-12 m-0">
                                    <label for="cnic" class="col-form-label">Amount</label>
                                    <input v-model="form.amount" name="loanAmount" :min="loanCategory.minAmount" :max="loanCategory.maxAmount" placeholder="Enter Amount" class="form-control r-0 light s-12 date-picker" type="number">
                                </div>
                                <div class="form-group col-12 m-0">
                                    <label for="cnic" class="col-form-label">Description</label>
                                    <textarea v-model="form.description" name="description" placeholder="Loan description" class="form-control r-0 light s-12 date-picker"></textarea>
                                </div>
                                <!-- <div class="form-group col-12 m-0">
                                    <div class="form-check m-2 ">
                                        <input class="form-check-input" type="checkbox" id="inlineFormCheck">
                                        <label class="form-check-label" for="inlineFormCheck">
                                            Terms & Conditions
                                        </label>
                                    </div>
                                </div> -->
                            </div>
                            <br/>
                            <span v-if="submitting" class="btn btn-primary">...</span>
                            <button v-else type="submit" class="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
       
        <modal modalId="pay"  modalTitle="Initiate Payment" modalSize="md" :link="payLink">
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <iframe id='ifr' frameborder="0" :src="payLink" scrolling="no" width="400" height="700"></iframe>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import {mapActions,mapGetters,mapState} from 'vuex'
import modal from '../../components/MonoModal.vue'
//import Connect from '@mono.co/connect.js'
import notify from 'toastr'
export default {
    components:{
        modal
    },
    props:{

    },

    data(){
        return {
            form:{
                amount:0,
                loanCategoryId: this.$route.params.id
            },
            payLink:null
        }
    },
    
    computed:{
        ...mapState({
            loading:state=>state.loading,
            submitting:state=>state.submitting
        }),

        ...mapGetters('loanCategoryStore',['loanCategory']),
        ...mapGetters('authStore',['authUser']),
        ...mapGetters('settingStore',['settings']),
        ...mapGetters('cardStore',['userCards']),
        
    },

    created(){
        var that = this
        //if(Object.entries(this.authUser).length == 0){
            this.getUser().then((res)=>{
                if(res.status == 200){
                    that.getSettings().then(()=>{
                        if(res.data.loanProcessor=='mono'){
                            that.getUserBanks(this.authUser._id).then((res=>{
                                if(res.status == 200 && that.userBanks.length == 0){
                                    notify.warning("You need to add your bank to proceed")
                                    that.$router.push({name:'user-banks'})
                                    return
                                }
                            }))
                        }

                        that.getUserCards(that.authUser._id).then((res)=>{
                            if(res.status == 200 && that.userCards.length == 0){
                                notify.warning("You need to add your debit cards to proceed")
                                that.$router.push({name:'user-cards'})
                                return
                            }
                        })
                    })


                    if(! that.authUser.hasNextOfKin){
                        notify.warning("You need to add your guarantor to proceed")
                        this.$router.push({name:'next-of-kin'})
                        return
                    }
                }
            })
        //}

        //if(Object.entries(this.settings).length == 0){
           
           
        //}
        
       

        

        this.getLoanCategory(this.$route.params.id)

        //alert()
    },

    methods:{
        ...mapActions('loanCategoryStore',['getLoanCategory']),
        ...mapActions('authStore',['getUser']),
        ...mapActions('loanStore',['create']),
        ...mapActions('settingStore',['getSettings']),
        ...mapActions('cardStore',['getUserCards']),
        ...mapActions('bankStore',['getUserBanks']),

        submit(){
            //confirm("Are you sure")
           this.create(this.form).then(res=>{
               if(res.status == 200){
                //    const monoWidget = new Connect({
                //         key: process.env.VUE_APP_MONO_PUBLIC_KEY,
                //         scope: "payments",
                //         payment_type: "recurring-debit",
                //         data: {
                //             payment_id: res.data.id,
                            
                //         },
                //         onSuccess: ({code}) => console.log("data", code),
                //     });
                //     console.log("link", res.data.payment_link),
                //     monoWidget.setup();
                //     monoWidget.open()
                //     console.log("amount",res.data.amount)
                //console.log(document.querySelectorAll('.pay'))
                    if(this.settings.loanProcessor == 'mono'){
                        this.payLink = res.data.payment_link
                        this.$bvModal.show('pay')
                    }else{
                        this.$router.push({name:'user-loan-history'})
                    }
               }
           })

           //location.
           
        },

        away(){
            alert()
        }
    }
}
</script>