export default {
    
    users(state,data){
        state.users = data
    },

    storeUser(state,data){
        state.users.push(data)
    },

    deleteUser(state,data){
        let users = state.users
        let index = users.findIndex(ele=>ele.id==data.id)
        users.splice(index,1)
    },

    totalUsers(state,data){
        state.totalUsers = data.total
    },

    profilePhoto(state,data){
        state.profilePhoto = data
    },

    profile(state,data){
        state.profile = data
    },

    income(state,data){
        return state.income = data
    },
}