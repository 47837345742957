export default {

    loanCategories(state){
        return state.loanCategories
    },

    loanCategory(state){
        return state.loanCategory
    },

    single(state){
        return (id)=>{
            return state.loanCategories.find(ele=>ele.id==id)
        }
    }
}